<div class="container" [ngClass]="'type-' + data.scoreType">
  <div class="ad-score">
    <span><animated-numbers [numbers] = "data.score" [duration]="1000"></animated-numbers></span>
    <span>Score</span>
    <svg id="svg" width="150" height="150" viewBox="0 0 150 150" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="glow">
          <feGaussianBlur stdDeviation="4" result="coloredBlur" />
          <feMerge>
            <feMergeNode in="coloredBlur"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      <circle r="65" cx="75" cy="75" fill="transparent"></circle>
      <circle id="score-bar" r="65" cx="75" cy="75" transform="rotate(40 75 75)" fill="transparent" [attr.stroke-dashoffset]="percentage" stroke-dasharray="408" filter="url(#glow)"></circle>
    </svg>
    <img class="bolt-circle" src="/assets/images/bolt-circle.svg" />
    <img class="bolt-sign" src="/assets/images/bolt-sign.svg" />
  </div>
  <div class="ad-score-conclusion">
    <span>{{title}}</span>
    <span>{{text}}</span>
  </div>
</div>
