import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { IBiApiRequests } from '../interfaces/bi-api-requests.interface';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { ErrorService } from './error.service';
import { IAiApiRequests } from '../interfaces/ai-api-requests.interface';
import accountNameIds from './../models/mock/accounts-names-ids.json';
import { filter } from 'rxjs/operators';
import { ErrorType } from '../enums/error.enum';
import { IAiApiResponses } from '../interfaces/ai-api-responses.interface';
import aiResp from './../models/mock/ai-score-resp.json'
import { ISuggestions } from '../interfaces/suggestion.interface';
import { Iscores } from '../interfaces/scores.interface';
import { IVideoFileData } from '../interfaces/video-file-data.interface';
import { EScoreType } from '../enums/score-type.enum';
import suggestionsTemplates from './../models//mock/ai-sugges.json'



@Injectable({
  providedIn: 'root'
})
export class AiDataService {

  aiCurrentData:any;

  constructor(private apiService: ApiService, private authService: AuthService, private errService: ErrorService) {}

  private _aiData$ = new Subject<any>();
  public aiData$ = this._aiData$.asObservable();
  updateAiAdData(aiAdData: any) {
    this._aiData$.next(aiAdData);
    this.aiCurrentData = aiAdData
  }

  private _aiVisited$ = new Subject<boolean>();
  public aiVisited$ = this._aiVisited$.asObservable();
  updateAiVisited(visited: boolean) {
    this._aiVisited$.next(visited);
    if(!visited) this.aiCurrentData = null;
  }

  setLabels(labels: string[]) {
    this.reqBody.blackList = labels;
  }


  setScoreType(scoreTypes: IAiApiRequests.IscoreType[]) {
    if (scoreTypes.length > 0) {
      this.reqBody.scoreType = scoreTypes[0];
    }


  }

  setAccounts(accountKeys: string[]) {
    let accIds = accountKeys.map(account => accountNameIds[account]);
    this.reqBody.accountId = accIds;
  }


  reqBody: IAiApiRequests.IScorePayload = {
    scoreType: null, videoId: "",

  }

  runVideoAnalyze(videoData: IVideoFileData.videoData) {
    let videoId = videoData.remoteFileName;

    this.apiService.startUploadAnalyze({ video_id: videoId }).subscribe();
    this.apiService.pollReq(this.apiService.checkForVideoAnalyzeStatus({ video_id: videoId }))
      .subscribe(
        () => {
          this.getVideoScore({ ...this.reqBody, videoId }, videoData);
        }, (err) => {
          throw this.errService.create({ message: err, type: ErrorType.UNKNOWN })
        })
  }


  getVideoScore(body: IAiApiRequests.IScorePayload, videoData: IVideoFileData.videoData) {
    this.apiService.getVideoScore(body).subscribe((data) => {
      if (data) {

        let res = this.parse(data, videoData)
        this.updateAiAdData(res);
        return;
      }
      throw this.errService.create({ message: "data from server is empty", type: ErrorType.VIDEO_SCORE_ERROR })
    }, (err) => {
      throw this.errService.create({ message: err, type: ErrorType.UNKNOWN })
    })
  }

  suggestions(videoData: IVideoFileData.videoData): ISuggestions.Suggestions | undefined {
    let { localFileName } = videoData;
    let templateHolder = suggestionsTemplates.find(sugg => sugg.videoId === localFileName);
    let suggestions = templateHolder?.suggestions || [];
    let possibleScoreType = EScoreType.AWESOME;
    let possibleScoreValue = 88;
    return {
      suggestions,
      possibleScoreType,
      possibleScoreValue
    }
  }

  parseFromPercetsToDecimal(score: number = 0): number {

    score = score || 0;
    score = score * 100;
    score = parseInt(score.toString());
    return score

  }

  getScoreType(score) {
    if (score < 31) {
      return EScoreType.MEDIOCRE;
    }
    if (score < 41) {
      return EScoreType.AVERAGE;

    }
    if (score < 56) {
      return EScoreType.GOOD;
    }

    return EScoreType.AWESOME;
  }

  scores(data: IAiApiResponses.IAiApiResponse = aiResp): Iscores.Scores | void {


    let avgScore = data.avg_ctr_score || data.avg_cvr_score || data.avg_watch_rate_score;
    avgScore = this.parseFromPercetsToDecimal(avgScore);


    let score = data.score_threshold;
    score = this.parseFromPercetsToDecimal(score);

    let { total } = data.video_count;
    let below = Object.values(data.video_count.below_threshold)[0];
    let belowRate: number = below / total;

    const percentsStyle = {
      style: 'percent',
      maximumFractionDigits: 0

    };


    let formatter = new Intl.NumberFormat("en-US", percentsStyle);
    let belowRateStr: string = formatter.format(belowRate);
    let scoreType = this.getScoreType(score)




    return {
      top: { score: `${score}` }, bottom: [{ score: `${avgScore}` }, { score: belowRateStr }], scoreType
    }

  }

  parse(data: IAiApiResponses.IAiApiResponse, videoData: IVideoFileData.videoData): any {
    let suggestions = this.suggestions(videoData);
    let scores = this.scores(data);
    return {
      suggestions,
      scores,
      videoData


    }

  }


}
