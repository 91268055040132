import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppConstants } from '../common/constants';
import { AuthError, UnkonwnError } from '../models/errors.model';
import { IBiApiRequests } from '../interfaces/bi-api-requests.interface';
import { IBiApiResponses } from '../interfaces/bi-api-responses.interface';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { ErrorService } from './error.service';



@Injectable({
  providedIn: 'root'
})
export class DataService {




  pageTitle: string = '';

  // readonly loading$ = new BehaviorSubject<boolean>(false);
  private _searchEnabled$ = new BehaviorSubject<boolean>(false);
  readonly searchEnabled$ = this._searchEnabled$.asObservable();
  readonly showPinnedRow$ = new BehaviorSubject<Array<string | undefined>>([]);

  private _loading$ = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading$.asObservable();
  updateLoading(value: any) {
    this._loading$.next(value);
  }

  private _searchButtonClicked$ = new BehaviorSubject<boolean>(false);
  public searchButtonClicked$ = this._searchButtonClicked$.asObservable();
  updateSearchButton(val: boolean) {
    this._searchButtonClicked$.next(val);
  }




  setAccounts(val: any[]) {

    this.reqBody.accountName = val;
  }

  getAccounts() {

    return this.reqBody.accountName;
  }
  setPlatforms(val: any[]) {

    this.reqBody.platform = val;
  }

  getPlatforms() {

    return this.reqBody.platform;
  }
  setStartDate(val: any) {
    this.reqBody.startDate = val;
  }
  setEndDate(val: any) {
    this.reqBody.endDate = val;
  }
  setFreeSearch(val: any) {
    this.reqBody.freeSearch = val;
    // this.getBiResults(this.reqBody)
  }

  setCpa(val: any) {

    this.reqBody.cpa = val;
    return this;
  }

  setCpaGa(val: any) {

    this.reqBody.cpaGA = val;
    return this;
  }

  setResults(val: any) {
    this.reqBody.results = val;
    return this;
  }

  setResultsGa(val: any) {
    this.reqBody.resultsGA = val;
    return this;
  }

  setSpend(val: any) {
    this.reqBody.spend = val;
    return this;

  }

  setImpressions(val: any) {
    this.reqBody.impressions = val;
    return this;
  }

  setLinkClicks(val: any) {
    this.reqBody.linkClicks = val;
    return this;
  }

  setThumbstop(val: any) {
    this.reqBody.thumbstop = val;
    return this;
  }

  setCtr(val: any) {
    this.reqBody.ctr = val;
    return this;
  }

  setCvr(val: any) {
    this.reqBody.cvr = val;
    return this;
  }


  getCpa() {
    return this.reqBody.cpa;
  }

  getCpaGa() {
    return this.reqBody.cpaGA;
  }

  getResults() {
    return this.reqBody.results;
  }

  getResultsGa() {
    return this.reqBody.resultsGA;
  }

  getSpend() {
    return this.reqBody.spend;
  }

  getImpressions() {
    return this.reqBody.impressions;
  }

  getLinkClicks() {
    return this.reqBody.linkClicks;
  }

  getThumbstop() {
    return this.reqBody.thumbstop;
  }

  getCtr() {
    return this.reqBody.ctr;
  }

  getCvr() {
    return this.reqBody.cvr;
  }
  getCvrGa() {
    return this.reqBody.cvrGA;
  }

  setVisualLabels(labels: string[]) {

    if (labels.length == 0) {
      this.reqBody.visualSearch = undefined;
      return;
    }
    this.reqBody.visualSearch = { labelName: labels, secondsInVideo: [] }

  }


  updateSearchEnabled(val: boolean) {
    this._searchEnabled$.next(val)
  }

  private _pageTitle$ = new BehaviorSubject<string>(AppConstants.BI_PAGE_TITLE);
  readonly pageTitle$ = this._pageTitle$.asObservable();
  updatePageTitle(title: string) {
    this._pageTitle$.next(title)
  }

  private _biAdData$ = new BehaviorSubject<[]>([]);
  readonly biAdData$ = this._biAdData$.asObservable();
  updateBiAdData(biAdData: any) {
    this._biAdData$.next(biAdData)
  }

  reqBody: IBiApiRequests.IApiRequestAdsPayload =
    {
      numResults: 999,
      sortBy: "cpa",
      sortOrder: 1,
    }

  constructor(private apiService: ApiService, private authService: AuthService, private errService: ErrorService) { }

  getBiResults(body: IBiApiRequests.IApiRequestAdsPayload = this.reqBody) {

    if (!this._searchEnabled$.value) {
      return;
    }

    if (body.accountName) this.showPinnedRow$.next(body.accountName);


    this.updateLoading(true);

    this.apiService.fetchBiResults(body).subscribe(
      (data: IBiApiResponses.IApiResponseAds) => {
        let ads: any = data;

        this.updateBiAdData(ads);
        this.updateLoading(false);

        return ads;
      }
    )
  }

  getFiltersOptions(body: IBiApiRequests.IApiRequestFiltersPayload = { email: "rgreen@first.media", get_action: 'ad_filters_ng' }) {
    this.apiService.fetchFiltersOptions(body).subscribe(
      (data: IBiApiResponses.IApiResponseFilter) => {

        console.log('res', { data: data.statusCode });
      },
      (err: any) => {
        if (err instanceof AuthError) {
          //alert('auth error')
          //refresh token
        } else if (err instanceof UnkonwnError) {
          //alert('unknwon error')
          //refresh token
        } else {
          //alert('???no error known ?!!?!')
        }
        //this.errService.handler.next
      }
    )
  }
}
