export const NO_PERMISSIONS = "User does not have permissions";
 export const AppConstants = {

  //app Header text
  BI_PAGE_TITLE:"Ads Analysis",
  AI_PAGE_TITLE:"Video Predictive Analytics",

  //buttons labels



  // Table headers
  SELECT : "selected",
  ADSET_NAME : "Adset name",
  PLATFORM  : "Platform",
   CPA : "CPA",
   CTR : "CTR",
   CVR : "CVR",
   START_DATE : "Start date",
   LAST_ACTIVE : "Last active",
   DAYS_ACTIVE : "Days active",
   IMPRESSIONS : "Impressions",
   OUTBOUND_CLICKS : "Outbound clicks",
   RETENTION : "Retention",
   SPEND : "Spend",
   THUMBSTOP : "Thumbstop",
   SEC_WATCHED_3 : "3 Sec watched",
   VIDEO_NAME : "Video name'",
   CAMPAIGN_NAME : "Campaign name",
   VIDEO_THURPLAY : "Video thurplay",
   WATCH_RATE : "Watch rate",
   ACCOUNT : "Account",
   RESULTS : "Results",
   MAX_VIDEO_SIZE : 250000000,
 }

export const AWS_REGION_DEFAULT = 'us-east-1';

export const HTTP_UPLOAD_PROGRESS = 'httpUploadProgress'



export const AI_API_HOST = "https://creativeexplore.fmtracking.net:18085";


export const biApiResponseKeys = {
  AD_MANAGER_URL: "ad_manager_url",
  _ID: "_id",
  ID: "id",
  AD_ID: "ad_id",
  ACCOUNT_ID: "account_id",
  ACCOUNT_NAME: "account_name",
  CAMPAIGN_NAME: "campaign_name",
  CREATED_TIME: "created_time",
  ADSET_NAME: "adset_name",
  AD_NAME: "ad_name",
  IMAGE_URL: "image_url",
  COLLECTION_TITLE: "collection_title",
  VIDEO_THRUPLAY_WATCHED: "video_thruplay_watched",
  VIDEO_3_SEC_WATCHED: "video_3_sec_watched",
  DATE: "date",
  CREATIVE_TYPE: "creative_type",
  TITLE_ID: "title_id",
  DESCRIPTION_ID: "description_id",
  TITLE: "title",
  DESCRIPTION: "description",
  COLLECTION_ID: "collection_id",
  CALL_TO_ACTION_LINK: "call_to_action_link",
  CREATIVE_URL: "creative_url",
  SPEND: "spend",
  RESULTS: "results",
  RESULTS_GA: "results_ga",
  LINK_CLICKS: "link_clicks",
  IMPRESSIONS: "impressions",
  WATCH_RATE: "watch_rate",
  DAILY_AVG_CPA: "daily_avg_cpa",
  DAILY_AVG_CTR: "daily_avg_ctr",
  DAILY_AVG_CVR: "daily_avg_cvr",
  QUALITY_RANKING_DAILY_AVG: "quality_ranking_daily_avg",
  CONVERSION_RATE_RANKING_DAILY_AVG: "conversion_rate_ranking_daily_avg",
  ENGAGEMENT_RATE_RANKING_DAILY_AVG: "engagement_rate_ranking_daily_avg",
  CPA: "cpa",
  CPA_GA: "cpa_ga",
  CTR: "ctr",
  CVR: "cvr",
  CVR_GA: "cvr_ga",
  THUMBSTOP: "thumbstop",
  RETENTION: "retention",
  PLATFORM: "platform",
  START_DATE: "start_date",
  END_DATE: "end_date",
  DAYS_ACTIVE: "days_active",
  MIN_CTR: "minCtr",
  MIN_CVR: "minCvr",
  MIN_THUMBSTOP: "minThumbstop",
  ACCOUNT_NAME_CAMEL_CASE: "accountName"


}


export const THUMBNAIL_URL_PLACEHOLDER = `https://fm-video-collection.s3.amazonaws.com/ad_images/ACCOUNTID_ADID.jpeg`;
export const THUMBNAIL_URL_PLACEHOLDER_ACCOUNT = `ACCOUNTID`;
export const THUMBNAIL_URL_PLACEHOLDER_AD = `ADID`;
export const dates = {
  MONTH: "month",
  DAYS: "days",
  DATE_FORMAT: 'MMM DD, YYYY',
  DATE_FORMAT_SERVER: 'YYYY-MM-DD',
  START_DATE_KEY: "startDate",
  END_DATE_KEY: "endDate"
}

