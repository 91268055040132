import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBiApiResponses } from 'src/app/interfaces/bi-api-responses.interface';

@Injectable({
    providedIn: 'root'
})
export class MockData {
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    constructor(private httpClient: HttpClient) {

    }
    getFilters(): Observable<IBiApiResponses.IApiResponseFilter> {
        return this.httpClient.get<IBiApiResponses.IApiResponseFilter>('/assets/filters.json');
    }

    getAds(): Observable<IBiApiResponses.IApiResponseAds> {
        return this.httpClient.get<IBiApiResponses.IApiResponseAds>('/assets/ads.json');
    }
}