<div class="container-pinned-row"
[ngStyle]='{ width : colWidth }'>
<div class="v-separator" *ngIf="showDivider" ></div>
  <div class="data-container" *ngIf="_col !== 'image_url'" >
    <span>{{_value}}</span>
    <span class="title-value">{{title}}</span>
  </div>
  <div class="data-container-row-title" *ngIf="_col === 'image_url'">
    <span>{{_value}}</span>
    <span class="title-value">{{title}}</span>
  </div>
</div>
<!-- class="data-container"   [ngClass]="{'data-container-row-title': _col === 'image_url', 'data-container': _col !== 'image_url' }" -->
