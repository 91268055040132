<!--  [getRowStyle]="getRowStyle"   [getRowClass]="getRowClass"-->
  <ag-grid-angular *ngIf="showGrid"
    style="width: 100%; height: 76vh;"
    class="ag-theme-alpine-dark"
    [modules]="modules"
    [pinnedTopRowData]='pinnedTopRowData'
    [gridOptions]="gridOptions"
    [columnTypes]="columnTypes"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    (columnResized)="onColumnResized($event)"
    [defaultColDef]="defaultColDef"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChange($event)"
    (rowClicked)="rowClicked($event)"
    rowSelection="multiple"
    [pagination]="true"
    [suppressRowClickSelection]="true"
    (cellClicked)='onCellClicked($event)'
    (sortChanged)="onSortStateEvent($event)"
    [frameworkComponents]="frameworkComponents"
    dragAndDrop ="true">
</ag-grid-angular>
<!-- NOTE: initial screen or no result search -->
<div *ngIf="
(!showGrid && initialDataFetched === DataLoadState.firstFetchState) ||
(!showGrid && initialDataFetched >= DataLoadState.searchFetchState && loading)" class="class-1 column-center no-result-container">

  <div class="image-container">
    <img [src]="srcTableMsg">
    <img [src]="srcTableSearchMsg" class="image-container__search image-container__search--animated"  [class.image-container__search--animated]="loading">
  </div>

  <p  class="no-result">Make a selection above to<br/> run the search</p>
</div>

<!-- NOTE: no data fetched -->
<div *ngIf="
(!showGrid && initialDataFetched >= DataLoadState.searchFetchState) ||
(!showGrid && initialDataFetched >= DataLoadState.searchFetchState && !loading)" class="class-2 column-center no-result-container">

  <div class="image-container">
    <img [src]="srcNoSearchResult">
  </div>

  <p>
    <span class="no-row-data-text">We couldn't find anything...</span>
    <span class="no-row-data-text no-row-data-text-small">
      <br/> You can try to change account, <br/> 
        platform or a valid date range.
    </span>
  </p>
</div>

<!-- NOTE: search screen while result  -->
<div *ngIf="showGrid && loading && (rowData.length > 0)" class="class-3 column-center no-result-container backdrop-wrapper">

  <div class="image-container updating-result">
    <img [src]="srcTableSearchMsg" class="image-container__search--animated"  [class.image-container__search--animated]="loading">
  </div>

  <p>
    <span class="no-row-data-text search-row-data-text">Updating table results</span>
  </p>
</div>
<!-- <jw-modal id="custom-modal-2">
  <div> test</div>
</jw-modal> -->
