
<div class="dropdown-container"[ngClass]="{'disabled': disabled, 'single-select' : single }"
 clickElsewhere (clickElsewhere)="close()" >
  <span class="label">{{data.title}} <span class="semicolon" *ngIf="mandatory">*</span><span
      *ngIf="semicolon">:</span></span>
  <div class="select" [class.has-selected]="selectedItems.length > 0"    (click)="showPopup($event)" >
    <span *ngIf="selectedItems.length === 0">{{data.placeholderText}}</span>
    <span *ngIf="selectedItems.length > 0">
      <ng-container *ngIf="selectHasIcons">
        <img *ngFor="let item of selectedItems" src='/assets/icons/{{item.icon}}' class="svg-filter" alt='{{item.text}}' />
      </ng-container>
      <ng-container *ngIf="!selectHasIcons && !single">{{selectedItems.length}} selected</ng-container>
      <ng-container *ngIf="!selectHasIcons && single">{{selectedItems[0].text}}</ng-container>
    </span>
    <img   class="svg-filter down-arrow" src="../../../../assets/icons/down-arrow-icon-black.svg" alt="down-arrow">
  </div>

  <div #popup class="popup" [class.is-visible]="popupIsVisible" >
    <span class="label">{{data.title}}</span>
    <ul>
      <li *ngIf="!viewOnly && !single " >
        <label><input type="checkbox" (change)="toggleCheckAll($event)" [(ngModel)]="checkedAll"/> All</label>
      </li>
      <li *ngFor="let item of data.inputs">
        <label><input type="checkbox" [disabled]="viewOnly" value="{{item.key}}" [(ngModel)]="item.isChecked" (change)="check($event , item.key)" /> <img class="svg-filter" src='/assets/icons/{{item.icon}}' alt='{{item.text}}' *ngIf="item.icon" /> {{item.text}}</label>
      </li>
      <li *ngIf="!viewOnly" class="button-item">
        <div class="button" (click)="checkData()" [class.disabled]="checkRequirements()">Select <span *ngIf="!single">{{getChecked().length > 0 ? '(' +
            getChecked().length + ')' :''}}</span></div>
      </li>
    </ul>
  </div>

</div>
