import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'fm-vid-cell',
  templateUrl: './vid-cell.component.html',
  styleUrls: ['./vid-cell.component.scss']
})
export class VidCellComponent implements ICellRendererAngularComp {

  image_url: string
  playIcon:string
  constructor() { }
  agInit(param: ICellRendererParams) {
    if(param.value !== undefined){

      this.image_url = param.value
    }
  }
  refresh(param: ICellRendererParams): boolean {
    return true;
  }
}
