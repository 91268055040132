import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'fm-ai-score-ad',
  templateUrl: './ai-score-ad.component.html',
  styleUrls: ['./ai-score-ad.component.scss']
})
export class AiScoreAdComponent implements OnInit {

  @Input() data: any = {};

  private designRadius = 65;
  public percentage = 2 * Math.PI * this.designRadius;

  public cases = [
    {
      scoreType: 1,
      title: `Mediocre`,
      text: `This creative isn’t ready to go. The model predicts it will not perform well. Please take a look at the recommendations below`
    },
    {
      scoreType: 2,
      title: `Average`,
      text: `This creative might not work well. The model predicts it will perform moderately well. Please take a look at the creative recommendations below`
    },
    {
      scoreType: 3,
      title: `Good score`,
      text: `This creative will work. The model predicts it will perform well. Take a look at some of the optional creative actions advised below`
    },
    {
      scoreType: 4,
      title: `Awsome!`,
      text: `This creative is ready to go and predicted to perform at the top of your category`
    }
  ];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    setTimeout(() => {
      this.percentage = this.getPreparedPercentage(this.data.score);
    }, 500);
  }

  ngAfterViewInit() {}

  getPreparedPercentage(percentage: number) {
    return 2 * Math.PI * (this.designRadius - this.designRadius * percentage / 100);
  }

  get title() {
    return this.cases.find( value => value.scoreType === this.data.scoreType)?.title;
  }

  get text() {
    return this.cases.find( value => value.scoreType === this.data.scoreType)?.text;
  }


}
