<div class="container">
  <fm-ai-score [data] = "videoAnalyzingResponseData[dataIndex-1]"></fm-ai-score>
  <div class="buttons">
    <button (click)="setDataIndex(1)">1</button>
    <button (click)="setDataIndex(2)">2</button>
    <button (click)="setDataIndex(3)">3</button>
    <button (click)="setDataIndex(4)">4</button>
  </div>
  <div class="buttons">
    <button (click)="setVideoIndex(1)">1</button>
    <button (click)="setVideoIndex(2)">2</button>
    <button (click)="setVideoIndex(3)">3</button>
    <button (click)="setVideoIndex(4)">4</button>
    <button (click)="setVideoIndex(5)">5</button>
  </div>
  <br/>
  <fm-ai-suggestions [data] = "suggestionsResponseData[videoIndex-1]"></fm-ai-suggestions>
</div>
