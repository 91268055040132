import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ID } from 'aws-sdk/clients/s3';
import * as moment from 'moment';
import { dates } from 'src/app/common/constants';
import { DateRangeDropdownComponent } from 'src/app/date-range-dropdown/date-range-dropdown.component';
import { IDropdownWorker } from 'src/app/interfaces/dropdown-closer.interface';
import { DropdownsManagerService } from 'src/app/services/dropdowns-manager.service';



@Component({
  selector: 'fm-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeComponent),
      multi: true
    }
  ]
})

export class DateRangeComponent implements OnInit, IDropdownWorker {


  dateSelected: boolean
  showDateRangeParent: boolean;
  _startDate: any;
  _endDate: any;
  dateSelectionStr: string;
  @Output() startDate: EventEmitter<string> = new EventEmitter();
  @Output() endDate: EventEmitter<string> = new EventEmitter();
  @ViewChild('dateRangeDrpodown', { static: true }) dateRangeDropdown: DateRangeDropdownComponent;
  text: string = 'Select date range';
  constructor(private elementRef: ElementRef) { }
  ngOnInit(): void {

  }

  close(): void {
    this.showDateRangeParent = false;
  }

  open(): void {
    this.showDateRangeParent = true;
  }


  openDateRange() {
    this.open()

  }


  dateChangeHelper(value: any) {
    this.dateSelected = value.dateSelected;
    this._startDate = value.startDate;
    this._endDate = value.endDate;
    this.dateSelectionStr = `${this._startDate.format(dates.DATE_FORMAT_SERVER)} - ${this._endDate.format(dates.DATE_FORMAT_SERVER)}`;
    this.text = this.dateSelectionStr;
    this.startDate.emit(this._startDate.format(dates.DATE_FORMAT_SERVER))
    this.endDate.emit(this._endDate.format(dates.DATE_FORMAT_SERVER))

  }

  isDateSelected(value: any) {

    this.dateChangeHelper(value);
    if (!value.init) {
      this.close()
    }



  }
  onCloseDropdown(event) {

    if (event.target && !this.elementRef.nativeElement.contains(event.target)) {
      //this.dateRangeDropdown.dateRangeContainer.clickCancel(null);
      this.close();
    }

  }



}
