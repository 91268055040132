import { Injectable } from '@angular/core';
import { AuthError, GeneralError, NoVideoIdError, PollingEndedError, UnkonwnError, VideoScoreError } from '../models/errors.model';

import { ErrorType } from '../enums/error.enum';
import { IErrorPayload } from '../interfaces/error.interface';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() {


  }

  create(payload: IErrorPayload) {
    if (payload.type == ErrorType.AUTH) {
      return new AuthError(payload.message);
    }
    else if (payload.type == ErrorType.GENERAL) {
      return new GeneralError(payload.message);
    }
    else if (payload.type == ErrorType.POLLING_ENDED) {

      return new PollingEndedError(payload.message);
    } else if (payload.type == ErrorType.NO_VIDEO_ID) {
      return new NoVideoIdError(payload.message);
    }
    else if (payload.type == ErrorType.VIDEO_SCORE_ERROR) {
      return new VideoScoreError(payload.message);
    }


    return new UnkonwnError(payload.message);

  }


}


