<div #minmax class="min-max">
    <div (click)="onClick()">
        <div class="title-wrapper">
            <img src="/assets/icons/funnel.svg">
            <div *ngIf="!filtered" class="title-label">Filter By</div>
            <div *ngIf="filtered" class="title-label" [class.filtered]="filtered">Filtered</div>
            <img *ngIf="!filtered" class="arrow" src="/assets/icons/drop-icon-gray.svg">
            <img *ngIf="filtered" class="arrow" src="/assets/icons/drop-icon-yellow.svg">
        </div>
    </div>
    <fm-min-max-tray [show]="showTray" [(filtered)]="filtered"></fm-min-max-tray>

</div>