<p *ngIf="file && loaded"
   class="upload-file__info">
  {{ file?.name }} <span>{{ file?.size | fileSize }}</span>
</p>
<div class="upload-file__controls"
     [class.upload-file__controls--small]="analyzed">
  <div class="tohide"
       [class.hidden]="unblurRecs">
    <label class="upload-file__input"
           [class.upload-file__input--disabled]="disabled || analyzing || analyzed"
           [class.upload-file__input--shadowed]="loaded">
      <input type="file"
             name="File Upload"
             [disabled]="disabled || analyzing || analyzed"
             accept="video/mp4,video/x-m4v,video/*"
             (change)="fileChangeEvent($event)"
             hidden />
      <div [class.upload-file__input-text-wrapper]="loaded">
        <ng-container *ngIf="!loadedToServer && !loadingToServer">
          <!-- local load -->
          <!-- do not delete Ron -->
                          <!-- <span> - disabled : {{disabled}}</span>
                          <span> - loaded :{{loaded}}</span> -->
                          <!-- <img [src]="loadedImg" *ngIf="!disabled && !loaded"/>
                          <img [src]="disabledImg" *ngIf="(disabled && !loaded) || (!disabled && loaded) "/> -->

                  <svg class="icon" [class.disabled]="disabled" [class.loaded]="loaded" width="57" height="56" viewBox="0 0 57 56" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.9131 0.5H36.4763C47.3015 0.5 56.0788 9.29412 56.0788 20.1444V35.7401C56.0788 46.5904 47.3015 55.3845 36.4763 55.3845H20.9131C10.0879 55.3845 1.31055 46.5904 1.31055 35.7401V20.1444C1.31055 9.29412 10.0879 0.5 20.9131 0.5Z" class="icon__border"/>
                    <path d="M34.3327 33.5914L28.7008 27.9478L23.0688 33.5914" class="icon__outline"  stroke-width="1.94946" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M28.7007 27.9478V40.6459" class="icon__outline"  stroke-width="1.94946" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M40.5108 36.9636C41.8842 36.2134 42.9691 35.0263 43.5944 33.5896C44.2197 32.153 44.3497 30.5485 43.9639 29.0295C43.5781 27.5106 42.6985 26.1636 41.4639 25.2013C40.2293 24.2389 38.71 23.716 37.1459 23.7151H35.3743C34.9481 22.0632 34.1538 20.5297 33.0511 19.2297C31.9483 17.9298 30.5658 16.8972 29.0076 16.2098C27.4493 15.5223 25.7557 15.1978 24.0543 15.2606C22.3528 15.3234 20.6877 15.7719 19.1841 16.5724C17.6806 17.3729 16.3776 18.5046 15.3733 19.8823C14.369 21.26 13.6895 22.848 13.3857 24.5268C13.082 26.2056 13.162 27.9315 13.6198 29.5749C14.0775 31.2182 14.9011 32.7362 16.0286 34.0147" class="icon__outline"  stroke-width="1.94946" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M34.3327 33.5914L28.7008 27.9478L23.0688 33.5914" class="icon__outline"  stroke-width="1.94946" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                          <p class="upload-file__text"
             [class.upload-file__text--disabled]="disabled"
             [class.upload-file__text--loaded]="loaded">
            <ng-container *ngIf="!loaded; else videoLoadedText">
              <b>Click to upload a video</b><br />
            </ng-container>

            <ng-template #videoLoadedText>
              <b>Choose a different video</b> <br />
            </ng-template>

            <span>max upload size ({{ MAX_VIDEO_SIZE | fileSize }})<br /></span>

            <!-- load to server -->
            <ng-container *ngIf="loaded">
              <p class="upload-file__separated-text">Or<br /></p>
              <span>click next to continue</span>
            </ng-container>
          </p>
        </ng-container>

        <ng-container *ngIf="loadingToServer || loadedToServer">
          <fm-progress [loaded]="
                (!analyzing && loadedToServer) || (!loadingToServer && analyzed)
              "
                       [iconed]="analyzing">
            <ng-container *ngIf="!analyzing"
                          ngProjectAs="text">
              {{ progress }} %
            </ng-container>
            <ng-container ngProjectAs="subtext">
              <ng-container *ngIf="loadingToServer">
                {{ speed | fileSize }}/s
              </ng-container>
              <ng-container *ngIf="loadedToServer && !analyzing">
                {{ file?.size | fileSize }}
              </ng-container>
              <ng-container *ngIf="analyzing"> Analyzing... </ng-container>
            </ng-container>
          </fm-progress>
          <p class="upload-file__text upload-file__text--loaded upload-file__text--margined"
             *ngIf="loadingToServer">
            <b>Uploading your video</b> <br />
            <span>This might take a few moments...</span>
          </p>
          <p class="upload-file__text upload-file__text--loaded upload-file__text--margined"
             *ngIf="loadedToServer && !analyzing">
            <b>Your video is ready</b> <br />
            <span>Click below to analyze</span>
          </p>
          <p class="upload-file__text upload-file__text--loaded upload-file__text--margined"
             *ngIf="analyzing">
            <b>Hang on while were building<br />
              your predictions...</b>
          </p>
        </ng-container>
      </div>
    </label>
    <video class="upload-file__video"
           (canplay)="loaded = true"
           [src]="fileUrl"></video>
  </div>
  <fm-video-player class="upload-file__player"
                   [class.upload-file__player--shown]="showStatistics"
                   *ngIf="videoUrl"
                   [src]="videoUrl"
                   [ratio]="'550:350'"
                   [width]="550">
  </fm-video-player>


</div>

  <!-- absolute to prevent jumping  *ngIf="loaded && !loadedToServer && !loadingToServer"-->
  <button *ngIf="loaded && !loadedToServer && !loadingToServer"
          type="button"
          class="upload-file__button"
          (click)="uploadToServer()">
    <img src="../../../assets/icons/open-bolt.svg" />
    Next
  </button>
  <button *ngIf="loadedToServer && !analyzed && !analyzing"
          type="button"
          class="upload-file__button"
          (click)="startAnalyze()">
    <img src="../../../assets/icons/open-bolt.svg" />
    Start Analyzing
  </button>
