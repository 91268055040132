import { Directive, EventEmitter, ElementRef, HostListener, Output, Input } from '@angular/core';

@Directive({ selector: '[clickElsewhere]' })

export class ClickElsewhereDirective {

  readonly inputType:string = 'dropdown'

  @Output() clickElsewhere = new EventEmitter<MouseEvent>();

  constructor(private elementRef: ElementRef) {}
  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = this.elementRef.nativeElement.contains(event.target);

      if (!targetElement) {
         this.clickElsewhere.emit(event);
      }
  }
}
