
export enum itemType {
  NUM,
  STR,
}

export enum DataLoadState {
  initialState = 0,
  firstFetchState = 1,
  searchFetchState =  2
}