import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { Guid } from 'guid-typescript';

import { Subscription } from 'rxjs';
import { EContainerEvents, EFileObjectStatus } from 'src/app/enums/file-upload.enum';
import { fnProgress } from 'src/app/interfaces/file-upload.interface';
import { FileObject } from 'src/app/models/file.model';
import { S3UploadService } from 'src/app/services/s3-upload.service';
import { VideoRegistrationService } from 'src/app/services/video-registration.service';

/**
 * Single file upload component.
 */
@Component({
  moduleId: module.id,
  selector: 'app-file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['file-upload.component.scss']
})
export class FileUploadComponent implements OnDestroy {
  @Input() fileObject: FileObject;
  @Input() oddRow: boolean;

  @Output() remoteUploadSrc
    : EventEmitter<string> = new EventEmitter();

  EFileObjectStatus = EFileObjectStatus;

  progress = 0;
  speed = 0;
  uploadError: string | undefined;
  containerEventSubscription: Subscription;
  uploadHandle: any;
  fileName: string

  constructor(private s3UploadService: S3UploadService, private videoRegistrationService: VideoRegistrationService) {
    this.fileName = Guid.create().toString().substring(0, 12);
    this.containerEventSubscription = s3UploadService.uploadContrainerEvent$.subscribe(
      containerEvent => {
        this.handleContainerEvent(containerEvent)
      }
    );
  }

  private handleContainerEvent(containerEvent: EContainerEvents) {
    if (containerEvent === EContainerEvents.Upload) {
      return this.fileObject.status === EFileObjectStatus.NotStarted && this.upload();
    } else if (containerEvent === EContainerEvents.Cancel) {
      return this.fileObject.status === EFileObjectStatus.Uploading && this.cancel();
    } else if (containerEvent === EContainerEvents.Delete) {
      return this.clear();
    }
  }

  upload() {
    this.fileObject.status = EFileObjectStatus.Uploading;
    this.uploadError = undefined;
    this.progress = 0;
    this.uploadHandle = this.s3UploadService.upload(this.fileObject.file, this.handleS3UploadProgress(), null, this.fileName);

  }

  private handleS3UploadProgress(): fnProgress {
    return (error: Error | undefined, progress: number | undefined, speed: number | undefined) => {

      if (error) {
        this.progress = 0;
        this.speed = 0;
        this.uploadError = error.message;
        this.fileObject.status = EFileObjectStatus.Failed;
      } else {
        this.progress = progress || this.progress;
        this.speed = speed || this.speed;
        if (this.progress === 100) {

          this.fileObject.status = EFileObjectStatus.Uploaded;
          let fileName = `upload_${this.fileName}.${this.fileObject.file.name.split('.').reverse()[0]}`;
          this.remoteUploadSrc
            .emit(`https://fm-video-collection.s3.amazonaws.com/craig-uploaded-videos/${fileName}`);

          this.videoRegistrationService.registerVideo({ file_name: fileName, account_id: "upload" });


        }
      }
    };
  }

  cancel() {
    if (this.fileObject.status === EFileObjectStatus.Uploading) {
      this.fileObject.status = EFileObjectStatus.Canceled;
      this.s3UploadService.cancel(this.uploadHandle);
    }
  }

  clear() {
    if (this.fileObject.status !== EFileObjectStatus.Uploading) {
      this.fileObject.status = EFileObjectStatus.Deleted;
      this.s3UploadService.publishFileUploadEvent(this.fileObject);
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.containerEventSubscription.unsubscribe();
  }
}
