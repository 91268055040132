<fm-analytics-config [disabled]="disabledSelect"
[accountsData]="accounts"
[labelsData]="labels"
[metricData]="metrics"
(selectedItemsEvent)="disabled = $event"></fm-analytics-config>
<div class="ai-recommend">
  <div [hidden]="!disabled || aiVisited"
       class="ai-recommend__config-text">
    You need to select an account, labels and benchmark before you can upload a
    video
  </div>
<!-- <div class="ai-result" *ngIf="showRecs"> -->
<div class="ai-result" *ngIf="aiVisited">
  <div class="ai-top-area">
    <div class="video-area">
      <div class="video-name">{{ videoLocalName}}</div>
      <fm-video-player  *ngIf="videoUrl"
        class="upload-file__player"
        [class.upload-file__player--shown]="showStatistics"
        [src]="videoUrl"
        [ratio]="'550:350'"
        [width]="550"
      >
      </fm-video-player>
    </div>
    <div class="score-area">
      <div class="score-title">Performance prediction</div>
      <fm-ai-score [data] = "scoreData" ></fm-ai-score>
    </div>
  </div>

    <fm-ai-suggestions  [data]="suggestionsData"></fm-ai-suggestions>
</div>
  <fm-upload    *ngIf="!aiVisited"
  [disabled]="disabled"
  (analyzeInProgress)="onAnalyzeStart()" (analyzeEnd)="onAnalyzeEnd()"
  (finishVideoUpload)="finishVideoUpload($event)"></fm-upload>

</div>
   <!-- *ngIf="!showRecs" -->
