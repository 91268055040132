import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ESelectionType } from 'src/app/enums/selection-type.enum';

@Component({
  selector: 'fm-labels-dropdown',
  templateUrl: './labels-dropdown.component.html',
  styleUrls: ['./labels-dropdown.component.scss']
})
export class LabelsDropdownComponent implements OnInit{

  @Input() data: any = [];
  @Input() disabled: boolean = false;
  @Input() viewOnly: boolean = false;
  @Input() minSelected: number = 1;
  @Input() titleSelected: string = "excluded";
  @Input() textButton: string = "Exclude";
  @Input() selectionType: ESelectionType = ESelectionType.EXCLUDE;
  @Input() title: string = "Visual Labels:";
  @Input() explanationText: string = "Select the labels you wish to exclude from the recommendation";
  @Input() dropdownTitle: string;
  @Input() placeholderText: string = "Select";


  @Output() selectedItemsEvent = new EventEmitter<string[]>();

  @ViewChild('popup') popup: any;
  @ViewChild('searchClear') searchClear: any;

  selectedItems: any[] = [];
  popupIsVisible = false;
  withoutExcluding = false;
  ESelectionType: typeof ESelectionType = ESelectionType;

  constructor() { }
  close(): void {
    this.popupIsVisible = false;
  }

  open(): void {
    this.popupIsVisible = true;
  }

  ngOnInit(): void {
    this.data.inputs.forEach((input: any) => {
      if (input.isChecked) { this.selectedItems.push(input) };
    });
    if (this.selectionType == ESelectionType.EXCLUDE) {
      this.continueWithoutExcluding();
    } else {
      this.checkData();
    }

  }

  checkData() {
    // see what is checked and update selected ItemsKeys
    this.selectedItems = [];
    this.data.inputs.forEach((input: any) => {
      if (input.isChecked) { this.selectedItems.push(input) };
    })

    this.popupIsVisible = false;

    // send selected to the parent component
    this.selectedItemsEvent.emit(this.selectedItems.map(item => item.key));
  }

  continueWithoutExcluding() {
    this.selectedItems = [];
    this.withoutExcluding = true;
    this.popupIsVisible = false;
    this.selectedItemsEvent.emit([]);
  }

  toggleState(category) {
    if (this.viewOnly) return;
    const categoryIndex = this.data.inputs.findIndex(input => input.key === category.key);
    if (categoryIndex > -1) this.data.inputs[categoryIndex].isChecked = !this.data.inputs[categoryIndex].isChecked;
  }

  showPopup($event: Event) {
    if (this.searchClear && this.searchClear.nativeElement.contains($event.target)) {
      $event.stopPropagation();
      $event.stopImmediatePropagation();
      return;
    }
    if (this.disabled) return;
    // $event.stopPropagation();

    // reset selected and not submitted values
    this.data.inputs = this.data.inputs.map((input: any) => {
      input.isChecked = !!this.selectedItems.find((item: any) => item.key === input.key);
      return input;
    });

    this.open();
  }

  getChecked() {
    return this.data.inputs.filter((input: any) => input.isChecked === true);
  }

  checkRequirements() {
    return this.minSelected > 0 && this.minSelected > this.data.inputs.filter(input => input.isChecked).length;
  }

  clearSearch(event) {

    this.selectedItems = [];
    this.selectedItemsEvent.emit([]);
    this.data.inputs.forEach((input: any) => {
      input.isChecked = false;
    });
    event.stopPropagation();
    if (this.selectionType == ESelectionType.EXCLUDE) {
      this.withoutExcluding = true;
    }
  }

}
