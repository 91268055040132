<div #tray *ngIf="show" class="min-max-tray">
    <div class="title-wrapper">
        <img src="/assets/icons/funnel.svg">
        <div class="title-label">Filter By</div>
    </div>
    <div>
        <fm-min-max-input [type]="data.type" [(val)]="this[data.val]" *ngFor=" let data of config">{{data.label}}
        </fm-min-max-input>
    </div>
    <div class="controls">
        <button class="clearall-btn btn" (click)="onClearAllClicked()">Clear All</button>
        <button [disabled]="!dirty" class="apply-btn btn" (click)="onApplyClicked()">Apply</button>
    </div>


</div>