import {
  Component,
  ElementRef,
  Input,
  AfterViewInit,
  ViewChild,
  OnChanges,
  SimpleChanges
} from "@angular/core";

@Component({
  selector: "animated-numbers",
  templateUrl: "animated-numbers.component.html",
  styleUrls: ["animated-numbers.component.scss"]
})
export class AnimatedNumbersComponent implements AfterViewInit, OnChanges {
  @Input() duration: number = 1000;
  @Input() numbers: number = 0;
  @Input() steps: number = 12;
  @ViewChild("animatedNumbers") animatedNumbers: ElementRef | undefined;

  animateCount() {
    if (!this.duration) {
      this.duration = 1000;
    }

    this.counterFunc(this.numbers, this.duration, this.animatedNumbers);
  }

  counterFunc(endValue, durationMs, element) {

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    function step() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      if (element) element.nativeElement.textContent = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }

  ngAfterViewInit() {
    if (this.numbers) {
      this.animateCount();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["numbers"]) {
      this.animateCount();
    }
  }
}
