<div class="container">
  <div class="left-group">

		<div class="searchbar">
			<div class="search-icon"></div>
			<input #searchInput type="text" class="search-input" ngDefaultControl [(ngModel)]="valFreeSearch" name="valFreeSearch"
      (keyup.enter)="freeSearch($event)"
      (keyup)="freeSearchKeyup($event)"
      id="search-input"
      placeholder="Ad name, Video name, Campaign name, Ad ID">
			<button *ngIf="showClearSearch" #searchClear (click)="clearSearch($event)" class="search-clear" id="search-clear"></button>
		</div>
    <fm-labels-dropdown textButton="Select" labelsSelected="Labels Selected" [selectionType]="ESelectionType.INCLUDE"
      [data]="labels"
      titleSelected="Labels Selected" explanationText="Select the labels you want to search by" [minSelected]=0
      [viewOnly]="disabledLabels" (selectedItemsEvent)="selectLabels($event)">
    </fm-labels-dropdown>

    <!-- <div class="rankings">
      <img src="../../../../assets/icons/rankings-icon.svg" alt="">
      <span>Ranking</span>
      <img class="down-arrow" src="../../../../assets/icons/down-arrow-icon.svg" alt="">
    </div> -->

    <fm-min-max></fm-min-max>
  </div>

<div class="right-group">
<div class="compare-group" *ngIf="isRowSelected">
  <span class="compare">Compare</span>
    <label class="switch">
      <input type="checkbox"(click)="onClickCompare()">
      <span class="slider round"></span>
    </label>
</div>


  <button [disabled]="!searchEnabled" [class.disabled]="!searchEnabled" class="apply-btn"
    (click)="onSearchButtonClicked($event)">Search ads</button>
</div>
</div>
