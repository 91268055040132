<div class="min-max-input-wrapper">
    <div class="min-max-label">
        <ng-content></ng-content>
    </div>
    <div class="min-max-input">

        <img *ngIf="!val && types[type].icon" [class]="types[type].class" [src]="types[type].icon" />

        <input [min]="0" (input)="onChange($event)" [decimal]="types[type].decimal" type="number" digitOnly
            [value]="val" #textInput class="search-input" id="search-input" [placeholder]="types[type].placeholder">
    </div>
</div>