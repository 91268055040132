import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'fm-ai-score-percent',
  templateUrl: './ai-score-percent.component.html',
  styleUrls: ['./ai-score-percent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AiScorePercentComponent implements OnInit {

  @Input() data: any = {};

  public cases = [
    {
      scoreType: undefined,
      text: `The average creative score of your historical results`
    },
    {
      scoreType: 1,
      text: `The model predicts it will perform lower than <value %> of your category.`
    },
    {
      scoreType: 2,
      text: `The model predicts it will perform lower than <value %> of your category.`
    },
    {
      scoreType: 3,
      text: `The model predicts it will perform better than <value %> of your category.`
    },
    {
      scoreType: 4,
      text: `The model predicts it will perform better than <value %> of your category.`
    }
  ];

  constructor() { }

  ngOnInit(): void {}

  get text() {
    const varHolder = /<value %>/gi;
    return this.cases.find( value => value.scoreType === this.data.scoreType)?.text.replace(varHolder, `<span class="score">${this.data.score}</span>`);
  }
}
