import { Injectable, OnInit } from '@angular/core';
import { IDropdownWorker } from '../interfaces/dropdown-closer.interface';

@Injectable({
  providedIn: 'root'
})
export class DropdownsManagerService {
  private dropdownsClosers: IDropdownWorker[];


  public register(dc: IDropdownWorker) {
    this.add(dc);
    this.watch(dc);

  }

  private add(dc: IDropdownWorker) {
    if (!this.dropdownsClosers) {
      this.dropdownsClosers = [dc];
      console.log("🚀 ~ file: dropdowns-manager.service.ts ~ line 20 ~ DropdownsManagerService ~ add ~ this.dropdownsClosers ", this.dropdownsClosers )
    } else {
      this.dropdownsClosers.push(dc);
      console.log("🚀 ~ file: dropdowns-manager.service.ts ~ line 22 ~ DropdownsManagerService ~ add ~ dropdownsClosers", this.dropdownsClosers)
    }
    return this;
  }

  private watch(dc: IDropdownWorker) {
    dc.open = ((callback: Function, dc: IDropdownWorker) => {
      return () => {
        this.closeAll();
        callback.apply(dc);
      }
    })(dc.open, dc);

  }

  private closeAll() {
    this.dropdownsClosers.forEach(dc => dc.close.apply(dc))
  }




}
