import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColumnApi, ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'fm-pinned-row-renderer',
  templateUrl: './custom-pinned-row-renderer.component.html',
  styleUrls: ['./custom-pinned-row-renderer.component.scss']
})
export class CustomPinnedRowRendererComponent implements  ICellRendererAngularComp {
  public params: ICellRendererParams;
  _value:string = '';
  title:string = '';
  _col:string = '';
  column: any;
  colWidth:number
  showDivider:boolean

  constructor() { }

  agInit(params: ICellRendererParams): void {
    if(params){
      this.params = params
    } else {
      return
    }
     this.column = params.api.getColumnDef(params.value.colId);

    if(typeof params.value === 'object') {
      this._value =  params.value.value
      this.title =  params.value.title
      this._col =  params.value.colId
      if(this._col === 'image_url' || this._col === 'ad_name' ){
        this.showDivider = false;
      }
      else {
        this.showDivider  = true;
      }
    } else {
      this.showDivider  = true;
    }
  }

  refresh(param: ICellRendererParams): boolean {
    return true;
  }

}
