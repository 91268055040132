import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { IBiApiResponses } from '../interfaces/bi-api-responses.interface';
import { ILogin } from '../interfaces/login-api.interface';
import { ApiService } from './api.service';
import { AuthService, UserLoginState } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  constructor(private apiService: ApiService, private authService: AuthService) { }
  login(body: ILogin.LoginApiRequest = { email: "rgreen@first.media", password: '123456' }): Subscription {
    let authService = this.authService;
    return this.apiService.login(body).subscribe(
      (data: ILogin.LoginApiResponse) => {
        if ((data as ILogin.LoginApiFailure).err) {
          authService.logout();
          return;
        }
        console.log('res', { data });

        localStorage.setItem('authorization', (data as ILogin.LoginApiSuccess).idToken);
        authService.login();

      },
      (error) => {
        alert(error);
        console.error(error)
      }
    )
  }


}
