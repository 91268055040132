import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from 'src/app/common/constants';
import { VideoPlayerComponent } from 'src/app/components/video-player/video-player.component';
import { DataService } from 'src/app/services/data.service';
import { ModalService } from 'src/app/services/modal.service';
import { TableService } from 'src/app/services/table.service';

@Component({
  selector: 'app-bi-ads',
  templateUrl: './bi-ads.component.html',
  styleUrls: ['./bi-ads.component.scss'],
})
export class BiAdsComponent implements OnInit {
  vidSrc: string = '';
  vidName: string = "video test";
  frameworkComponents: {} = {}
  defaultColsArr
  defaultColDef
  columnTypes

  @ViewChild(VideoPlayerComponent) player: VideoPlayerComponent

  constructor(private modalService: ModalService, private dataService: DataService, private tableService: TableService) { }

  ngOnInit(): void {
    this.dataService.updatePageTitle(AppConstants.BI_PAGE_TITLE);
    /*
      config table
      @frameworkComponents
      @defaultColsArr
      @defaultColDef
    */
    this.frameworkComponents = this.tableService.frameworkComponents;
    this.defaultColsArr = this.tableService.defaultColsArr;
    this.defaultColDef = this.tableService.defaultColDef
    this.columnTypes = this.tableService.columnTypes
  }

  doSearch() {

  }
  setVidSrc(event: string) {
    this.vidSrc = event;
    setTimeout(() => { this.openModal('custom-modal-7') }, 600)
  }
  onHideVideo() {
    this.vidSrc = '';
  }
  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.player.stopPlayer()
    this.modalService.close(id);
    this.vidSrc = '';
  }
}
