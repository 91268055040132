export default class RefTableData {

  static PLATFORM = [
    { platformName: 'Facebook', icon: 'facebook-icon' },
    { platformName: 'Tiktok', icon: 'tiktok-icon' },
    { platformName: 'Youtube', icon: 'youtube-icon' }]

  static TABLE_EMPTY_IMAGE: string = "../../assets/images/table-msg.svg"
  static TABLE_NO_SEARCH_RESULT: string = "../../assets/images/no-search-result.svg"
  static TABLE_EMPTY_SEARCH_iMAGE: string = "../../assets/images/search.svg"
  static VID_BASS_URL: string = '';

}
