<div class="date-picker" (click)="openDateRange()" [ngClass]="{  'show': !showDateRangeParent }">
    <img class="img-icon" src="../../../../assets/icons/date-icon.svg" alt="date icon" />
    <span [ngClass]="{selected:dateSelected}">{{text}}</span>
    <!-- <img class="down-arrow" class="down-arrow" src="../../../../assets/icons/down-arrow-icon.svg"
            alt="down-arrow-icon" /> -->
    <svg [ngClass]="{selected:dateSelected}" class="down-arrow" width="12" height="8" viewBox="0 0 12 8" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.55273 7.65859C6.45887 7.76583 6.34317 7.85178 6.21339 7.91066C6.08361 7.96954 5.94274 8 5.80023 8C5.65771 8 5.51685 7.96954 5.38706 7.91066C5.25728 7.85178 5.14159 7.76583 5.04773 7.65859L0.247728 2.17759C0.121096 2.03306 0.0387869 1.85511 0.0106497 1.66502C-0.0174875 1.47493 0.00973988 1.28076 0.0890732 1.10574C0.168406 0.930723 0.296485 0.782268 0.457986 0.67814C0.619487 0.574011 0.807569 0.51862 0.999728 0.518592L10.5997 0.518592C10.792 0.518427 10.9802 0.57368 11.1418 0.677733C11.3035 0.781786 11.4317 0.930229 11.5112 1.10529C11.5906 1.28034 11.6179 1.47459 11.5898 1.66477C11.5617 1.85495 11.4794 2.033 11.3527 2.17759L6.55273 7.65859Z" />
    </svg>
    <fm-date-range-dropdown (cancel)="close()" (onCloseDropdown)="onCloseDropdown($event)" #dateRangeDrpodown
        [ngClass]="{ 'hide': !showDateRangeParent, 'show': showDateRangeParent }" (isDateSelected)="isDateSelected($event)"
        class="date-picker-fm">
    </fm-date-range-dropdown>

</div>
