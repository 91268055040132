<div class="recommendation-item-container">
  <div class="time">
    <img src="assets/icons/time-icon.svg" />
    <span>Sec {{data.title.start}}</span>
    <span *ngIf="data.title.end"> - {{data.title.end}}</span>
  </div>
  <div class="recommendation">
    <img src="assets/icons/bolt-icon.svg" />
    <span [innerHTML]="recommendation"></span>
  </div>
</div>
