<div class="dropdown-container" [class.disabled]="disabled"
clickElsewhere  (clickElsewhere)="close()"
>
  <span class="label">{{title}}</span>
  <div class="select" [class.has-selected]="selectedItems.length > 0 || withoutExcluding" (click)="showPopup($event)">
    <span *ngIf="!withoutExcluding && selectedItems.length === 0">{{placeholderText}}</span>
    <div class="title-selected" *ngIf="selectedItems.length > 0 || withoutExcluding">
      <span>
        {{selectedItems.length}} {{titleSelected}}
      </span>
      <button *ngIf="selectedItems.length > 0" #searchClear (click)="clearSearch($event)" class="search-clear"
        id="search-clear"></button>
      </div>
    <img class="svg-filter down-arrow" src="../../../../assets/icons/down-arrow-icon-black.svg" alt="down-arrow">
  </div>

  <div #popup class="popup" [class.is-visible]="popupIsVisible">
    <div class="popup-header">
      <span *ngIf="dropdownTitle" class="title">{{dropdownTitle}}</span>
      <span class="explanation">{{explanationText}}</span>
    </div>
    <div class="categories-container" [class.view-only]="viewOnly">
      <div class="item" *ngFor="let category of data.inputs">
        <span [class]="'selection-type-'+ESelectionType[selectionType].toLocaleLowerCase()"
          [class.selected]="category.isChecked"
          (click)="toggleState(category)">{{category.text}}</span>
      </div>
    </div>
    <div class="popup-footer" *ngIf="!viewOnly">
      <div *ngIf="selectionType == ESelectionType.EXCLUDE" class="transparent-button" (click)="continueWithoutExcluding()"
        [class.disabled]="!checkRequirements()">
        Continue without excluding</div>
      <div class="button" (click)="checkData()" [class.disabled]="checkRequirements()">{{textButton}}
        {{getChecked().length > 0
        ? getChecked().length : ''}}</div>
    </div>

  </div>
</div>
