import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'fm-ad-name',
  templateUrl: './ad-name.component.html',
  styleUrls: ['./ad-name.component.scss']
})
export class AdNameComponent implements  ICellRendererAngularComp ,OnInit {

  adName:String
  adId:string
  adManagerUrl:string
  constructor() { }

  agInit(param: ICellRendererParams) {
    this.adId = param.data.ad_id
    this.adName = param.data.ad_name
    this.adManagerUrl = param.data.ad_manager_url
  }
  refresh(param: ICellRendererParams): boolean {
    return true;
  }
  ngOnInit(): void {
  }
  goToLink(event:MouseEvent,url: string){
    event.stopPropagation()
    console.log("🚀 ~ file: table.component.ts ~ line 223 ~ TableComponent ~ goToLink ~ url", url)
    window.open(url, "_blank");
}
}
