import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalService {
  private modals: any[] = [];
  public isOpen: boolean = false

  add(modal: any) {
    this.modals.push(modal);
  }

  remove(id: string) {
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(id: string) {
    const modal = this.modals.find(x => x.id === id);
    this.isOpen = true;
    modal.open();
  }

  close(id: string) {
    const modal = this.modals.find(x => x.id === id);
    this.isOpen = false;
    modal.close();
  }

  closeAll() {
    this.modals.forEach(modal => {
      modal.close();
    });
    this.isOpen = false;
  }
}
