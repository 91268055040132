import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, tap } from 'rxjs/operators';
import { ILogin } from '../interfaces/login-api.interface';
import { IAuth } from '../interfaces/auth.interface';
import { ErrorType } from '../enums/error.enum';
import { IErrorPayload } from '../interfaces/error.interface';
import { ErrorService } from '../services/error.service';
import { NO_PERMISSIONS } from '../common/constants';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private errorService: ErrorService) { }
    thowIfNeed(message: string | null) {
        if (message) {
            let payload: IErrorPayload = { message, type: ErrorType.AUTH }
            let authException = this.errorService.create(payload);
            throw authException;
        }
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let idToken = localStorage.getItem('authorization');
        //idToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjM1MDZmMzc1MjI0N2ZjZjk0Y2JlNWQyZDZiNTlmYThhMmJhYjFlYzIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vY3JlYXRpdmUtZXhwbG9yZSIsImF1ZCI6ImNyZWF0aXZlLWV4cGxvcmUiLCJhdXRoX3RpbWUiOjE2NDE3NDkwMDQsInVzZXJfaWQiOiI4WmZlR0lEV1JhT0Q5Tjh3YmNyaXhXaUZBTmkxIiwic3ViIjoiOFpmZUdJRFdSYU9EOU44d2Jjcml4V2lGQU5pMSIsImlhdCI6MTY0MTc0OTAwNCwiZXhwIjoxNjQxNzUyNjA0LCJlbWFpbCI6InJncmVlbkBmaXJzdC5tZWRpYSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInJncmVlbkBmaXJzdC5tZWRpYSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.Nm6qoI6XGZEuARDTwNLRwGxJtcPeTtZpPJW6KHRQEuB9RtbJ-gg11gm1sX65dt8Cm-nvyS2r8h9HkxODqgnpQKaUY1iGO-qMA4kHbOjWMmLYCysjN9eSPz0r993KpkWiGDVt_V49-BikgYXSUiywya6KGu9tVkRMdb8J2loJsXUP2xtYS0AAm-d6rmRXOEt2Spg5pm5trK3IFz9KjdulXfh_DqrCJcTKkxZjtselsHpTKvJkFYPMeM1OHZwrcx0p-LMNJz8nNmT5RhfEYEsx5AlFqDV7y1_FLyfMRVNzf-T_v_x99bi1e23OqzpjS97g1DEsYbIVfnpPekm3s2XTwQ';
        if (idToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: idToken
                }

            });
        }
        return next.handle(request).pipe(
            catchError(() => {
                throw this.errorService.create({ type: ErrorType.UNKNOWN, message: 'unknown' })
            }),
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    let message: string | null = null;
                    let authFail = evt.body as IAuth.authFailure;
                    let loginFail = evt.body as ILogin.LoginApiFailure;
                    if (authFail.body && authFail.body == NO_PERMISSIONS) {
                        message = NO_PERMISSIONS;
                    }
                    if (loginFail.err) {
                        message = loginFail.err.message;
                    }
                    return this.thowIfNeed(message);

                }
            }));
    }
}