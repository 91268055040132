import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'fm-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input() data: any = [];
  @Input() disabled: boolean = false;
  @Input() viewOnly: boolean = false;
  @Input() minSelected: number = 0;

  @Input() single: boolean = false;
  @Input() mandatory: boolean = false;
  @Input() semicolon: boolean = false;
  @Output() selectedItemsEvent = new EventEmitter<string[]>();

  @ViewChild('popup') popup: any;

  selectHasIcons = false;
  selectedItems: any[] = [];
  popupIsVisible = false;
  checkedAll = false;
  constructor(private eRef: ElementRef) { }
  close(): void {
    this.popupIsVisible = false;
  }

  open(): void {
    this.popupIsVisible = true;
  }

  ngOnInit(): void {
    this.data.inputs.forEach((input: any) => {
      if (input.isChecked) { this.selectedItems.push(input) };
      if (input.icon) this.selectHasIcons = true;
    })

  }

  check(event, key) {
    if (!this.single) {
      this.checkIfAllSelected();
      return;
    }
    const val = event.target.checked;
    this.data.inputs = this.data.inputs.map((input: any) => {
      input.isChecked = false;
      return input;
    });
    this.data.inputs.find((item: any) => item.key === key).isChecked = val;

  }

  toggleCheckAll(event: any) {
    if (event.target.checked) {
      // check all
      this.data.inputs = this.data.inputs.map((input: any) => {
        input.isChecked = true;
        return input;
      });
    } else {
      // uncheck all
      this.data.inputs = this.data.inputs.map((input: any) => {
        input.isChecked = false;
        return input;
      });
    }
  }

  checkIfAllSelected() {
    this.checkedAll = !this.data.inputs.find((item: any) => !item.isChecked);
  }

  checkData() {
    // see what is checked and update selected ItemsKeys
    this.selectedItems = [];
    this.data.inputs.forEach((input: any) => {
      if (input.isChecked) { this.selectedItems.push(input) };
    })

    this.close()

    // send selected to the parent component
    this.selectedItemsEvent.emit(this.selectedItems.map(item => item.key));
  }

  showPopup($event: Event) {
    // this.popupIsVisible = true;
    if (this.disabled) return;
   // $event.stopPropagation();
    // reset selected and not submitted values
    this.data.inputs = this.data.inputs.map((input: any) => {
      input.isChecked = !!this.selectedItems.find((item: any) => item.key === input.key);
      return input;
    });

    this.checkIfAllSelected();
    this.open();
  }

  getChecked() {
    return this.data.inputs.filter((input: any) => input.isChecked === true );
  }

  checkRequirements() {
    return this.minSelected > 0 && this.minSelected > this.data.inputs.filter(input => input.isChecked).length;
  }


}
