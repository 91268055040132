import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { MockData } from '../models/mock/mock-data';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class HttpHandlerService {

  headerSubscriber: any;
  private baseUrl = environment.baseUrl;
  public headers = new HttpHeaders();
  constructor(protected http: HttpClient, public httpService: HttpService, protected mockData: MockData, protected errorService: ErrorService) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');

    // let idToken = localStorage.getItem('authorization');
    // //idToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjM1MDZmMzc1MjI0N2ZjZjk0Y2JlNWQyZDZiNTlmYThhMmJhYjFlYzIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vY3JlYXRpdmUtZXhwbG9yZSIsImF1ZCI6ImNyZWF0aXZlLWV4cGxvcmUiLCJhdXRoX3RpbWUiOjE2NDE3NDkwMDQsInVzZXJfaWQiOiI4WmZlR0lEV1JhT0Q5Tjh3YmNyaXhXaUZBTmkxIiwic3ViIjoiOFpmZUdJRFdSYU9EOU44d2Jjcml4V2lGQU5pMSIsImlhdCI6MTY0MTc0OTAwNCwiZXhwIjoxNjQxNzUyNjA0LCJlbWFpbCI6InJncmVlbkBmaXJzdC5tZWRpYSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInJncmVlbkBmaXJzdC5tZWRpYSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.Nm6qoI6XGZEuARDTwNLRwGxJtcPeTtZpPJW6KHRQEuB9RtbJ-gg11gm1sX65dt8Cm-nvyS2r8h9HkxODqgnpQKaUY1iGO-qMA4kHbOjWMmLYCysjN9eSPz0r993KpkWiGDVt_V49-BikgYXSUiywya6KGu9tVkRMdb8J2loJsXUP2xtYS0AAm-d6rmRXOEt2Spg5pm5trK3IFz9KjdulXfh_DqrCJcTKkxZjtselsHpTKvJkFYPMeM1OHZwrcx0p-LMNJz8nNmT5RhfEYEsx5AlFqDV7y1_FLyfMRVNzf-T_v_x99bi1e23OqzpjS97g1DEsYbIVfnpPekm3s2XTwQ';
    // if (idToken) {
    //   this.httpService.updateHeader('authorization', idToken);
    // }

    this.headerSubscriber = this.httpService.headers.subscribe(updatedValue => {
      if (updatedValue) {
        this.headers = this.headers.set(updatedValue.key, updatedValue.value);
      }
    });
  }

  protected get(url: string, params: {} | undefined, headers?: HttpHeaders): Observable<any> {
    return this.http.get(this.baseUrl + url, { params, headers: headers || this.headers });
  }

  protected delete(url: string, params: {} | undefined): Observable<any> {
    return this.http.delete(this.baseUrl + url, { params, headers: this.headers });
  }

  protected put(url: string, body: {}): Observable<any> {
    return this.http.put(this.baseUrl + url, body, { headers: this.headers });
  }

  protected post(url: string, body: {}): Observable<any> {
    return this.http.post(this.baseUrl + url, body, { headers: this.headers });
  }


}
