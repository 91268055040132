export enum EFileObjectStatus {
    NotStarted,
    Uploading,
    Uploaded,
    Canceled,
    Deleted,
    Failed
}

export enum EContainerEvents {
    Upload,
    Cancel,
    Delete
}