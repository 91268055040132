import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ISuggestions} from '../interfaces/suggestion.interface';
import suggestionTemplates from '../models/mock/suggestion-templates.json';

@Component({
  selector: 'fm-suggestion-item',
  templateUrl: './suggestion-item.component.html',
  styleUrls: ['./suggestion-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SuggestionItemComponent implements OnInit {

  @Input() data:ISuggestions.Suggestion;

  suggestionTemplates = suggestionTemplates;

  constructor() { }

  ngOnInit(): void {}

  get recommendation() {
    const varHolder = /<value %>/i;
    let template = this.suggestionTemplates.find( value => value.id === this.data.templateId)?.text;
    if (template) {
      this.data.items.forEach(item => {
        template = template?.replace(varHolder, `<span class="accent">${item}</span>`)
      });
      return template;
    } else return '';

    // return ?.text.replace(varHolder, `<span class="score">${this.data.score}</span>`);

    // return this.suggestionTemplates[0].text;
  }

}
