<div [ngClass]="{'file-row': true, row: true, odd: oddRow}">
  <div class="col-lg-4">
    <p class="name">{{fileObject.file.name}}</p>
    <p
      [ngClass]="{'text-primary': fileObject.status === EFileObjectStatus.Uploading, 'text-success': fileObject.status === EFileObjectStatus.Uploaded, 'text-danger': fileObject.status === EFileObjectStatus.Failed}">
      <strong *ngIf="fileObject.status !== EFileObjectStatus.NotStarted"
        [ngClass]="{'error': fileObject.status === EFileObjectStatus.Failed}">
        {{EFileObjectStatus[fileObject.status]}}
      </strong>
    </p>
    <strong class="error text-danger">{{uploadError}}</strong>
  </div>
  <div class="col-lg-4 text-right">
    <p class="size">{{fileObject.file.size | fileSize}}</p>
    <div class="progress">
      <div
        [ngClass]="{'progress-bar': true, 'progress-bar-striped': true, 'progress-bar-success': fileObject.status !== EFileObjectStatus.Failed, 'progress-bar-danger': fileObject.status === EFileObjectStatus.Failed }"
        role="progressbar" [ngStyle]="{'width': progress + '%'}" aria-valuenow="progress" aria-valuemin="0"
        aria-valuemax="100">
        <strong *ngIf="progress > 0">{{progress}}%</strong>
      </div>
    </div>
    <p *ngIf="fileObject.status === EFileObjectStatus.Uploading" class="speed">{{speed | fileSize}}/s</p>
  </div>
  <div class="col-lg-4 text-right">
    <button [disabled]="fileObject.status === EFileObjectStatus.Uploading" class="btn btn-success start"
      (click)="upload()">
      <i class="glyphicon glyphicon-upload"></i>
      <strong>Upload</strong>
    </button>
    <button [disabled]="fileObject.status !== EFileObjectStatus.Uploading" class="btn btn-warning cancel"
      (click)="cancel()">
      <i class="glyphicon glyphicon-ban-circle"></i>
      <strong>Cancel</strong>
    </button>
    <button [disabled]="fileObject.status === EFileObjectStatus.Uploading" class="btn btn-danger delete"
      (click)="clear()">
      <i class="glyphicon glyphicon-trash"></i>
      <strong>Clear</strong>
    </button>
  </div>
</div>