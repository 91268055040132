import { Injectable } from '@angular/core';
import { biApiResponseKeys } from '../common/constants';
interface percentsStyle {
  style: string;
  maximumFractionDigits:number;
  minimumFractionDigits: number;
}

@Injectable({
  providedIn: 'root'
})
export class FormatDataFactory {

  private percentsStyleCvr: percentsStyle;
  private percentsStyle: percentsStyle;
  private currencyStyle: { style: string; currency: string; };

  constructor() {
    this.init();
  }
  private init() {

    this.currencyStyle = {
      style: 'currency',
      currency: 'USD',

    };

    this.percentsStyle = {
      style: 'percent',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2

    };

    this.percentsStyleCvr = {
      style: 'percent',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2

    };
  }

  private accountName(values: string | Array<string>) {
    if (!values) {
      return values;
    }
    if (typeof values == 'string') {
      values = [values];
    }
    values = values.map(val => {
      return val.split(':').reverse()[0];
    });
    let ret = values.join();
    ret = ret.trim();
    return ret;

  }

  private customPercents(val: string | number): string {
    val = val + '%';
    return val;
  }

  private prepareFormatter(format: Function) {
    return { format };

  }

  public formatterOf(key: string) {

    switch (key) {
      case biApiResponseKeys.MIN_CTR:
      case biApiResponseKeys.MIN_CVR:
      case biApiResponseKeys.MIN_THUMBSTOP:
        return this.prepareFormatter(this.customPercents);
      case biApiResponseKeys.ACCOUNT_NAME:
        return this.prepareFormatter(this.accountName);
    }
    return this.prepareFormatter((val) => val);
  }

  public numberStyleFrom(key: string) {

    let ret;
    switch (key) {
      case biApiResponseKeys.RETENTION:
      case biApiResponseKeys.THUMBSTOP:
      case biApiResponseKeys.CTR:
        ret = this.percentsStyle;
        break;
      case biApiResponseKeys.CVR:
      case biApiResponseKeys.CVR_GA:
        ret = this.percentsStyleCvr;
        break;
      case biApiResponseKeys.SPEND:
      case biApiResponseKeys.CPA:
      case biApiResponseKeys.CPA_GA:
        ret = this.currencyStyle;
        break;
    }
    return ret;

  }


}
