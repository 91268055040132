<div class="video-analyzing-summary">

  <div class="video-analyzing-summary-top">
    <fm-ai-score-ad class="video-analyzing-summary-ads" [data]="{score: data.top.score, scoreType: data.scoreType}"></fm-ai-score-ad>
  </div>
  <div class="video-analyzing-summary-bottom">
    <fm-ai-score-percent class="video-analyzing-summary-percent" [data]="{score: data.bottom[0].score}"></fm-ai-score-percent>
    <fm-ai-score-percent class="video-analyzing-summary-percent" [data]="{score:data.bottom[1].score, scoreType: data.scoreType}"></fm-ai-score-percent>
  </div>
</div>
