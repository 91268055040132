<div class="conteiner">
  <div class="left-group">
    <div class="accounts">
      <fm-dropdown [data]="accounts" [minSelected]=1 [mandatory]="!!platform.mandatory"
       [semicolon]="!!platform.semicolon" 
        (selectedItemsEvent)="accountsSelectChanged($event)"></fm-dropdown>
      <!-- <span class="label"> Accounts: </span> <span class="select">Select</span>
      <img class="down-arrow" src="../../../../assets/icons/down-arrow-icon.svg" alt="down-arrow">
      -->
    </div>
    <!-- <div class="v-separator"></div> -->
    <!-- <div class="categories"> -->
      <!-- <fm-labels-dropdown [data]="categories" (selectedItemsEvent)="categorySelectChanged($event)"></fm-labels-dropdown> -->
      <!-- <span class="label">Platforms:</span> <span class="select">Select</span>
      <img class="down-arrow" src="../../../../assets/icons/down-arrow-icon.svg" alt="down-arrow"> -->
    <!-- </div> -->
    <div class="v-separator"></div>
    <div class="platforms">
      <fm-dropdown [data]="platform" [mandatory]="!!platform.mandatory" [semicolon]="!!platform.semicolon" [minSelected]=1
        (selectedItemsEvent)="platformSelectChanged($event)"></fm-dropdown>
      <!-- <span class="label">Platforms:</span> <span class="select">Select</span>
      <img class="down-arrow" src="../../../../assets/icons/down-arrow-icon.svg" alt="down-arrow"> -->
    </div>
  </div>


  <fm-date-range (startDate)="startDateChanged($event)" (endDate)="endDateChanged($event)"></fm-date-range>
</div>
