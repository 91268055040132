import { Component, OnInit, ElementRef, ViewChild, forwardRef } from '@angular/core';
import { TableService } from 'src/app/services/table.service';
import { DataService } from 'src/app/services/data.service';
import labelsData from '../../../models/mock/labels-selection-bi.json';
import { IinputData } from 'src/app/interfaces/inputs.interface';
import { ESelectionType } from 'src/app/enums/selection-type.enum';
import { ControlValueAccessor,NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'fm-search-bar-filters',
  templateUrl: './search-bar-filters.component.html',
  styleUrls: ['./search-bar-filters.component.scss'],
   providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchBarFiltersComponent),
      multi: true
    }]
})
export class SearchBarFiltersComponent implements OnInit, ControlValueAccessor {

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('searchClear') searchClear: ElementRef;
  searchEnabled = false;


  searchString: string = '';
  adIDString: string = '';
  selected: string = '';
  isRowSelected: boolean = false;
  isDoCompare: boolean = false;
  showClearSearch: boolean = false;
  disabledLabels:boolean = false;
  labels: IinputData.labelsData;
  ESelectionType: typeof ESelectionType = ESelectionType;
  valFreeSearch:string = '';

  constructor(private dataService: DataService, private tableService: TableService) { }

  ngOnInit(): void {
    this.tableService.isRowSelected$.subscribe(value => {
      this.isRowSelected = value
    })
    this.labels = labelsData;

    this.dataService.searchEnabled$.subscribe(val => this.searchEnabled = val)

  }
  freeSearch(event: any) {
    this.searchString = event.target.value;
    if(event.target.value !== ''){
      this.dataService.getBiResults()
    }
  }
  freeSearchKeyup($event: any){
    this.dataService.setFreeSearch($event.target.value)
    this.showClearSearch = $event.target.value !== '' ? true : false;
  }
  clearSearch(event: any) {
    this.searchInput.nativeElement.value = '';
    // this.searchInput.nativeElement.value = '';
    this.showClearSearch = false;
    this.dataService.setFreeSearch('')
  }

  onClickCompare() {
    this.isDoCompare = !this.isDoCompare
    this.isDoCompare ? this.isRowSelected = true : this.isRowSelected = false
    this.tableService.updateIsDoCompare(this.isDoCompare)
  }
  onSearchButtonClicked(event: MouseEvent) {
    event.stopPropagation()
    this.dataService.getBiResults();
    this.tableService.updateIsRowSelected(false);
    this.isDoCompare = false;
    this.dataService.updateSearchButton(true);
  }
  selectLabels(event: any) {
    this.dataService.setVisualLabels(event);

  }

  //---------
  onChange: any = () => {}
  onTouch: any = () => {}
  val= ""

  set value(val){
    console.log("🚀 ~ file: search-bar-filters.component.ts ~ line 85 ~ SearchBarFiltersComponent ~ setvalue ~ val", val)
    if( val !== undefined && this.val !== val){
    this.val = val
    this.onChange(val)
    this.onTouch(val)
    }

  }

  writeValue(value: any) {
    this.value = value
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }
}
