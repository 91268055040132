import { Injectable } from '@angular/core';
import { IVideoPayload } from '../interfaces/ai-video-sqs.interface';
import { ApiService } from './api.service';




@Injectable({
  providedIn: 'root'
})
export class VideoRegistrationService {

  tableName = "upload_videos";
  topic = "video_download_mysql:fm_creative_collection";
  sqsUrl = "https://sqs.us-east-1.amazonaws.com/510882379431/fb_api_data";
  constructor(private apiService: ApiService) { }


  async registerVideo(data: IVideoPayload) {
    //debugger;
    await (await this.apiService.sqsSendSqlMessege(data)).subscribe();
  };


}


