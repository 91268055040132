import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { AiRecommendComponent } from './pages/ai-recommend/ai-recommend.component';
import { BiAdsComponent } from './pages/bi-ads/bi-ads.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { UploadContainerComponent } from './pages/upload-page-example/component';
import { TestAiScoreComponent } from "./pages/tests/test-ai-score/test-ai-score.component";
import { AppConstants } from './common/constants'

const routes: Routes = [
  { path: 'ai', component: AiRecommendComponent },

  { path: 'upload', component: UploadContainerComponent },
  { path: 'tests/ai-score', component: TestAiScoreComponent },
  {
    path: 'bi', component: BiAdsComponent
  },
  { path: '', redirectTo: 'bi', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
