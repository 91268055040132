
<div class="container">
    <app-side-bar class="sidebar"></app-side-bar>
    <div class="content">
      <fm-header></fm-header>
      <main class="main">
        <router-outlet></router-outlet>
      </main>
    </div>
</div>
