<div class="daterange-wrapper" (clickElsewhere)="handleClickOutside($event)">
    <ngx-daterangepicker-material [maxDate]="today" #dateRange [locale]="{format: dateFormat,displayFormat: dateFormat}"
        class="daterange" (datesUpdated)="onDatesUpdated($event)" [(ngModel)]="selectModel"
        [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [startDate]="selectModel.startDate"
        [showCancel]="true" [ranges]="ranges" [linkedCalendars]="true" [endDate]="selectModel.endDate"
        [keepCalendarOpeningWithRange]="true">

    </ngx-daterangepicker-material>

<div class="daterange-label">
    {{dateRangeContainer.chosenLabel ? dateRangeContainer.chosenLabel : (selectModel.startDate.format(dateFormat) +
    ' - ' +
    selectModel.endDate.format(dateFormat) )}}
</div>
</div>