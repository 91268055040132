<div class="ai-suggestions-container">
  <div class="title">
    {{suggestConstants.SUGGESTION_TITLE}}
  </div>
  <div class="body">{{ suggestConstants.SUGGESTION_BODY}}</div>
  <!-- <div class="body" [ngClass]="'type-' + this.data.possibleScoreType" [innerHTML]="body"></div> -->
  <div class="data">

    <div *ngFor="let suggestion of data.suggestions" >
      <fm-suggestion-item [data]="suggestion"></fm-suggestion-item>
    </div>
  </div>
</div>
