<section class="recommendations">
  <h2 class="recommendations__header">
    <img src="../../../assets/icons/ai-studio-sb-icon.svg" />
    <p class="recommendations__header-text">
      Actionable Recommendations <br />
      <span class="recommendations__header-subtext">
        Applying one or more of the suggestions will improve the score of this
        creative
      </span>
    </p>
  </h2>
  <ul class="recommendations__list">
    <li *ngFor="let rec of recomendations; let i = index"
        [class.recommendations__list-item-loaded]="loadedRecomendation >= i + 2"
        class="recommendations__list-item">
      <img src="../../../assets/icons/ai-studio-sb-icon.svg" />
      <div>
        <p class="recommendations__list-item-timeframe">
          Timeframe: <span>{{ rec.timeframe }}</span>
        </p>
        <p class="recommendations__list-item-subtitle"
           [innerHTML]="rec.subtitle"></p>
      </div>
    </li>
  </ul>
</section>
