<div class="progress"
     [style.width]="size+'px'"
     [style.height]="size+'px'">
  <div class="progress__spinner"
       [style.width]="size+'px'"
       [style.height]="size+'px'"
       [style.border-color]="loaderProgressBackground"
       [style.border-top-color]="loaded ? loaderProgressBackground : loaderProgressColor"
       >
  </div>
  <img *ngIf="iconed"
       class="progress__icon"
       src="../../../../../assets/icons/thunder-rounded.svg">
  <b class="progress__spinner-text">
    <ng-content select="text"></ng-content>
  </b>
  <span class="progress__spinner-subtext">
    <ng-content select="subtext"></ng-content>
  </span>
</div>
