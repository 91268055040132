import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'fm-ai-score',
  templateUrl: './ai-score.component.html',
  styleUrls: ['./ai-score.component.scss']
})
export class AiScoreComponent implements OnInit {

  @Input() data: any = {};

  constructor() { }

  ngOnInit(): void {}

}
