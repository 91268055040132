



import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import moment from 'moment';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { dates } from '../common/constants';
import { DateRangeModule } from '../interfaces/date-range.interface';
@Component({
  selector: 'fm-date-range-dropdown',
  templateUrl: './date-range-dropdown.component.html',
  styleUrls: ['./date-range-dropdown.component.scss']
})

export class
  DateRangeDropdownComponent implements OnInit {

  selected: any;
  dateFormat: string
  today: any

  private _dateSelected: boolean = true;

  @Input() set dateSelected(value: boolean) {

    this._dateSelected = value;
    this.updateDateSelected();

  }

  @Input() set parent(value: boolean) {

    this._dateSelected = value;
    this.updateDateSelected();

  }
  @ViewChild('dateRange', { static: true }) dateRangeContainer: DaterangepickerComponent;
  @Output() isDateSelected: EventEmitter<any> = new EventEmitter();
  @Output() dateStart: EventEmitter<string> = new EventEmitter();
  @Output() dateEnd: EventEmitter<string> = new EventEmitter();
  @Output() onCloseDropdown: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();


  ranges: DateRangeModule.Iranges;
  selectModel: DateRangeModule.IDates;

  constructor(private elementRef: ElementRef, private cd: ChangeDetectorRef) {

  }


  ngAfterViewInit() {
    this.dateRangeContainer.clickCancel = ((callback: any, context: any) => {
      return (e: any) => {
        callback.apply(context);
        this.cancel.emit();
      };


    })(this.dateRangeContainer.clickCancel, this.dateRangeContainer)
  }



  onDatesUpdated(e: any): void {

    console.log(e);

    this.selected = e;
    if (!e.endDate || !e.startDate) {
      this.dateSelected = false;
      return;
    }

    this.dateSelected = true;

  }
  updateDateSelected(init = false): void {
    sessionStorage.setItem(dates.START_DATE_KEY, this.selected.startDate.toString())
    sessionStorage.setItem(dates.END_DATE_KEY, this.selected.endDate.toString())
    this.isDateSelected.emit({ dateSelected: this._dateSelected, ...this.selected, init });
  }

  getLastSessionDateRange(): DateRangeModule.IDates {

    let startDate = sessionStorage.getItem(dates.START_DATE_KEY);
    let endDate = sessionStorage.getItem(dates.END_DATE_KEY);
    if (startDate && endDate) {

      return {
        startDate: moment(startDate),
        endDate: moment(endDate)
      };

    }
    //this.dateRangeContainer.clickRange(null, 'Last 30 Days');
    return {
      startDate: moment().subtract(29, dates.DAYS as any).startOf(dates.DAYS as any),
      endDate: moment().subtract(0, dates.DAYS as any).startOf(dates.DAYS as any)
    };
  }

  ngOnInit() {
    this.ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, dates.DAYS as any), moment().subtract(1, dates.DAYS as any)],
      'Last 7 Days': [moment().subtract(6, dates.DAYS as any), moment()],
      'Last 30 Days': [moment().subtract(29, dates.DAYS as any), moment()],
      'This Month': [moment().startOf(dates.MONTH as any), moment().endOf(dates.MONTH as any)],
      'Last Month': [moment().subtract(1, dates.MONTH as any).startOf(dates.MONTH as any), moment().subtract(1, dates.MONTH as any).endOf(dates.MONTH as any)]
    }
    this.today = moment()
    this.dateFormat = dates.DATE_FORMAT;
    this.initSelectedDays();
    this._dateSelected = true;
    this.updateDateSelected(true);

    //this.dateRangeContainer.clickRange(null, 'Last 30 Days');
  }


  initSelectedDays() {
    this.selectModel = this.getLastSessionDateRange();
    this.selected = this.selectModel;
  }
  resetInnerDatepickerComponent() {
    this.dateRangeContainer.chosenLabel = `${this.selectModel.startDate.format(this.dateFormat)} - ${this.selectModel.endDate.format(this.dateFormat)}`;
    this.dateRangeContainer.ngOnInit();
    this.dateRangeContainer.updateView();
  }

  toCancel() {
    this.initSelectedDays();
    this.resetInnerDatepickerComponent();
  }

  handleClickOutside(event) {
    if (event.target && !this.elementRef.nativeElement.contains(event.target)) {
      this.toCancel();
      this.onCloseDropdown.emit(event);
    }
  }
}
