import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { bool } from 'aws-sdk/clients/signer';
import { PlyrComponent } from 'ngx-plyr';

@Component({
  selector: 'fm-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  // get the component instance to have access to plyr instance
  @ViewChild(PlyrComponent) plyr!: PlyrComponent;

  player!: Plyr;

  private _src!: string;
  @Input() title!: string;
  @Input() width!: number;
  @Input() ratio: string = '16:9';
  @Input() set src(value: string) {
    this._src = value;
    this.updateVideo();
  }


  videoSources!: Plyr.Source[];
  constructor() { }

  updateVideo() {
    this.videoSources = [{ src: this._src, size: this.width }]
  }

  played(event: Plyr.PlyrEvent) {

  }

  play(): void {
    this.player.play();
  }

  stopPlayer() {
    this.player.stop();
  }



  ngOnInit(): void {


    this.updateVideo();


  }
  ngOnDestroy() {
  }

}
