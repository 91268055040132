import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Guid } from 'guid-typescript';
import { AppConstants } from 'src/app/common/constants';
import { fnProgress } from 'src/app/interfaces/file-upload.interface';
import { IVideoFileData } from 'src/app/interfaces/video-file-data.interface';
import { S3UploadService } from 'src/app/services/s3-upload.service';
import { VideoRegistrationService } from 'src/app/services/video-registration.service';

@Component({
  selector: 'fm-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  host: { 'class': 'upload-file' }
})
export class UploadComponent implements OnInit {
  file: File | null = null;
  fileUrl: string | ArrayBuffer | null = null;
  videoUrl: string | null = null;
  MAX_VIDEO_SIZE = AppConstants.MAX_VIDEO_SIZE;
  filename: string | null = null;
  localFileName: string | null = null;
  progress: number = 0;
  speed: number = 0;
  loaded: boolean = false;
  loadingToServer: boolean = false;
  loadedToServer: boolean = false;


  disabledImg :string = "../../../../../assets/icons/upload-icon-disabled.svg";
  loadedImg:string =  "../../../../../assets/icons/upload-active-icon.svg";
  loadImgIcon:string ='';

  // FAKED CAUSE THERE IS NO API
  analyzing: boolean = false;
  analyzed: boolean = false;
  analyzingProgress: number = 0;

  unblurRecs: boolean = false;

  @Input() disabled = false;
  @Output() analyzeInProgress = new EventEmitter<boolean>();
  @Output() analyzeEnd = new EventEmitter<boolean>();
  @Output() finishVideoUpload = new EventEmitter<IVideoFileData.videoData>();
  @HostBinding('class.upload-file--wide') showStatistics: boolean = false;

  constructor(
    private s3UploadService: S3UploadService,
    private videoRegistrationService: VideoRegistrationService,
  ) { }

  ngOnInit(): void {
  }


  fileChangeEvent(event: any): void {
    const file = event.target.files && event.target.files[0];
    this.file = file;

    if (file) {

      const reader = new FileReader();
      reader.onload = (e) => {
        this.fileUrl = e!.target!.result;
        this.filename = Guid.create().toString().substring(0, 12);
      };
      reader.readAsDataURL(event.target.files[0]);
      event.target.value = null;
    }
  }

  uploadToServer(): void {
    this.loadingToServer = true;
    this.s3UploadService.upload(
      this.file!,
      this.handleS3UploadProgress(),
      null,
      this.filename!
    );
  }

  private handleS3UploadProgress(): fnProgress {
    return (
      error: Error | undefined,
      progress: number | undefined,
      speed: number | undefined
    ) => {
      if (error) {
        this.progress = 0;
        this.speed = 0;
        this.loadingToServer = false;
      } else {
        this.progress = progress || this.progress;
        this.speed = speed || this.speed;

        if (this.progress === 100) {
          this.loadingToServer = false;
          this.loadedToServer = true;
          let fileName = `upload_${this.filename}.${this.file!.name.split('.').reverse()[0]
            }`;
          // this.remoteUploadSrc
          //   .emit(`https://fm-video-collection.s3.amazonaws.com/craig-uploaded-videos/${fileName}`);
          this.videoUrl = `https://fm-video-collection.s3.amazonaws.com/craig-uploaded-videos/${fileName}`;

          this.videoRegistrationService.registerVideo({
            file_name: fileName,
            account_id: 'upload',
          });
        }
      }
    };
  }



  startAnalyze(): void {
    this.finishVideoUpload.emit({ remoteFileName: this.filename as string, localFileName: this.file?.name as string, size: this.file?.size, remoteUrl: this.videoUrl as string });
    this.analyzed = false;
    this.analyzing = true;

  }
}
