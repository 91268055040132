<section class="analytics-config__wrapper">
  <div class="select-wrapper">
    <fm-dropdown
      class="select"
      [viewOnly]="disabled"
      (selectedItemsEvent)="selectVertical($event, 'account')"
      [data]="accountsData"
      [minSelected]=0
    ></fm-dropdown>
    <fm-labels-dropdown [data]="labelsData" [minSelected]=1 [viewOnly]="disabled" dropdownTitle="Edit Labels"
      (selectedItemsEvent)="selectVertical($event, 'label')"></fm-labels-dropdown>
    <fm-dropdown
      (selectedItemsEvent)="selectVertical($event, 'metric')"
      [single]="true"
      [viewOnly]="disabled"
      [minSelected]=0
      [data]="metricData"
    ></fm-dropdown>
  </div>
</section>
