import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { EAdDataType } from 'src/app/enums/ad-data-type.enum';
import { DataService } from 'src/app/services/data.service';



@Component({
  selector: 'fm-min-max-tray',
  templateUrl: './min-max-tray.component.html',
  styleUrls: ['./min-max-tray.component.scss']
})
export class MinMaxTrayComponent implements OnInit, OnChanges {

  @Input() show = false;
  @Output() filteredChange = new EventEmitter();
  @Input() filtered: boolean;


  private _maxCpaGa: string | undefined;
  private _minSpend: string | undefined;
  private _minResultsGa: string | undefined;
  private _minImpressions: string | undefined;
  private _minLinkClicks: string | undefined;
  private _minThumbstop: string | undefined;
  private _minCtr: string | undefined;
  private _minCvr: string | undefined;
  dirty = false;
  typeCurrency: EAdDataType = EAdDataType.CURRENCY;
  typePercents: EAdDataType = EAdDataType.PERCENTS;
  typeSeconds: EAdDataType = EAdDataType.SECONDS;
  typeNumber: EAdDataType = EAdDataType.NUMBER;
  changes: { [item: string]: boolean } = {};

  config = [
    { type: this.typeCurrency, val: "maxCpaGa", label: "Max CPA GA" },
    { type: this.typeCurrency, val: "minSpend", label: "Min Spend" },
    { type: this.typeNumber, val: "minResultsGa", label: "Min Results GA" },
    { type: this.typeNumber, val: "minImpressions", label: "Min Impressions" },
    { type: this.typeNumber, val: "minLinkClicks", label: "Min Link Clicks" },
    { type: this.typePercents, val: "minCtr", label: "Min CTR" },
    { type: this.typePercents, val: "minCvr", label: "Min CVR" },
    { type: this.typeSeconds, val: "minThumbstop", label: "Min Thumbstop" }
  ];




  @Input() set maxCpaGa(value: string | undefined) {

    this._maxCpaGa = value;
    this.changes._maxCpaGa = this._maxCpaGa != this.dataService.getCpaGa();
    this.notify();
  }
  get maxCpaGa() {

    return this._maxCpaGa;
  }
  @Input() set minSpend(value: string | undefined) {

    this._minSpend = value;
    this.changes._minSpend = this._minSpend != this.dataService.getSpend();
    this.notify();


  }
  get minSpend() {

    return this._minSpend;

  }

  @Input() set minResultsGa(value: string | undefined) {

    this._minResultsGa = value;
    this.changes._minResultsGa = this._minResultsGa != this.dataService.getResultsGa();
    this.notify();

  }
  get minResultsGa() {

    return this._minResultsGa;

  }


  @Input() set minImpressions(value: string | undefined) {

    this._minImpressions = value;
    this.changes._minImpressions = this._minImpressions != this.dataService.getImpressions();
    this.notify();


  }
  get minImpressions() {

    return this._minImpressions;

  }


  @Input() set minLinkClicks(value: string | undefined) {

    this._minLinkClicks = value;
    this.changes._minLinkClicks = this._minLinkClicks != this.dataService.getLinkClicks();
    this.notify();


  }
  get minLinkClicks() {

    return this._minLinkClicks;

  }



  @Input() set minThumbstop(value: string | undefined) {

    this._minThumbstop = value;
    this.changes._minThumbstop = this._minThumbstop != this.dataService.getThumbstop();
    this.notify();


  }
  get minThumbstop() {

    return this._minThumbstop;

  }



  @Input() set minCtr(value: string | undefined) {

    this._minCtr = value;
    this.changes._minCtr = this._minCtr != this.dataService.getCtr();
    this.notify();


  }
  get minCtr() {

    return this._minCtr;

  }


  @Input() set minCvr(value: string | undefined) {

    this._minCvr = value;
    this.changes._minCvr = this._minCvr != this.dataService.getCvr();
    this.notify();


  }
  get minCvr() {

    return this._minCvr;

  }




  constructor(private dataService: DataService) { }
  notify() {

    this.dirty = Object.values(this.changes).includes(true);


  }

  ngOnInit(): void {

    this.initInputs();
  }

  ngOnChanges(changes: SimpleChanges) {

    console.log({ changes });
    if (changes.show && changes.show.currentValue) {
      this.maxCpaGa = this.maxCpaGa || this.dataService.getCpaGa()?.toString();
      this.minSpend = this.minSpend || this.dataService.getSpend()?.toString();
      this.minResultsGa = this.minResultsGa || this.dataService.getResultsGa()?.toString();
      this.minImpressions = this.minImpressions || this.dataService.getImpressions()?.toString();
      this.minLinkClicks = this.minLinkClicks || this.dataService.getLinkClicks()?.toString();
      this.minThumbstop = this.minThumbstop || this.dataService.getThumbstop()?.toString();
      this.minCtr = this.minCtr || this.dataService.getCtr()?.toString();
      this.minCvr = this.minCvr || this.dataService.getCvr()?.toString();
      this.notify();

    }
  }
  onApplyClicked() {

    this.dataService.setCpaGa(this._maxCpaGa).setSpend(this._minSpend).setResultsGa(this._minResultsGa).setImpressions(this._minImpressions)
      .setLinkClicks(this._minLinkClicks).setThumbstop(this._minThumbstop).setCtr(this._minCtr).setCvr(this._minCvr);
    this.filteredChange.emit(this.isFiltered());
    this.initChanges();
    this.notify();
    this.show = false;



  }
  initInputs() {

    this.maxCpaGa = undefined;
    this.minSpend = undefined;
    this.minResultsGa = undefined;
    this.minImpressions = undefined;
    this.minLinkClicks = undefined;
    this.minThumbstop = undefined;
    this.minCtr = undefined;
    this.minCvr = undefined;
    this.notify();

  }
  initChanges() {
    Object.keys(this.changes).forEach(item => { this.changes[item] = false }, this);
    this.notify();
  }
  isFiltered() {
    //debugger;
    return this.dataService.getCpaGa() != undefined ||
      this.dataService.getSpend() != undefined ||
      this.dataService.getResultsGa() != undefined ||
      this.dataService.getImpressions() != undefined ||
      this.dataService.getLinkClicks() != undefined ||
      this.dataService.getThumbstop() != undefined ||
      this.dataService.getCtr() != undefined ||
      this.dataService.getCvr() != undefined;
  }
  onClearAllClicked() {
    this.initInputs();
  }



}
