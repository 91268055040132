import { S3 } from 'aws-sdk';

export class S3Factory {
    private static s3Clients: { [key: string]: S3 } = {};
    static getS3(regionName: string) {
        if (!S3Factory.s3Clients[regionName]) {
            S3Factory.s3Clients[regionName] = new S3({
                region: regionName, accessKeyId: "AKIAXN4XWO2TW3RPVPX4",
                secretAccessKey: "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"
            });
        }
        return S3Factory.s3Clients[regionName] as S3;
    }
}