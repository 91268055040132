import { Component, OnInit } from '@angular/core';
import videoAnalyzingResponseData from '../../../models/mock/video-analyzing-summary.json';
import suggestionsResponseData from '../../../models/mock/ai-sugges.json';

@Component({
  selector: 'fm-test-ai-score',
  templateUrl: './test-ai-score.component.html',
  styleUrls: ['./test-ai-score.component.scss']
})
export class TestAiScoreComponent implements OnInit {

  videoAnalyzingResponseData = videoAnalyzingResponseData;
  suggestionsResponseData = suggestionsResponseData;

  dataIndex = 1;
  videoIndex = 1;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges() {}

  setDataIndex(val) {
    if (!videoAnalyzingResponseData[val-1]) alert(`Data with such index is missing`);
    else this.dataIndex = val;
  }

  setVideoIndex(val) {
    if (!suggestionsResponseData[val-1]) alert(`Data with such index is missing`);
    else this.videoIndex = val;
  }

}
