export enum EAdDataType {
    ID = 1,
    LINK,
    DEFAULT,
    CURRENCY,
    DATE,
    NUMBER,
    PERCENTS,
    STRING,
    SECONDS,
}
