import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { biApiResponseKeys, THUMBNAIL_URL_PLACEHOLDER, THUMBNAIL_URL_PLACEHOLDER_ACCOUNT, THUMBNAIL_URL_PLACEHOLDER_AD } from '../common/constants';
import RefTableData from '../common/RefTableData';
import { EAdDataType } from '../enums/ad-data-type.enum';
import { IBiApiResponses } from '../interfaces/bi-api-responses.interface';
import { FormatDataFactory } from './format-data-factory.service';
import { VidCellComponent } from '../components/table/vid-cell/vid-cell.component';
import { AdNameComponent } from '../components/table/ad-name/ad-name.component';
import { CustomPinnedRowRendererComponent } from '../components/table//custom-pinned-row-renderer/custom-pinned-row-renderer.component';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  private _isRowSelected$ = new BehaviorSubject<boolean>(false);
  readonly isRowSelected$ = this._isRowSelected$.asObservable();

  adDataTypes: { [x: string]: EAdDataType; };
  private _isDoCompare$ = new BehaviorSubject<boolean>(false);
  readonly isDoCompare$ = this._isDoCompare$.asObservable();

  defaultColsArr = [
    {
      colId: "image_url",
      headerName: 'Adset',
      field: 'image_url',
      // checkboxSelection: true,
      // headerCheckboxSelection: true,
      // headerCheckboxSelectionFilteredOnly: true,
      // pinned: true,
      // autoHeight: true,
      // minWidth: 150,
      // lockPosition: true,
      type:['firstColumn','cellRendererSelector'],
      // cellRenderer: 'VidCellRenderer',

    },
    {
      colId: "ad_name",
      headerName: 'Ad name', field: 'ad_name',
      pinned: true,
      minWidth: 368,
      cellRenderer: 'AdNameComponent',
      wrapText: false,
      lockPosition: true,


      type:['cellRendererSelector','cellStyle']
    },
    {
      colId: "spend",
      headerName: 'Spend', field: 'spend',

      sort: 'desc',
      width: 142,

      type:['cellRendererSelector','cellStyle','comparatorCell'],

    },
    {
      colId: "cpa_ga",
      headerName: 'CPA GA',
      field: 'cpa_ga',


      type:['cellRendererSelector','cellStyle','comparatorCell']

    },
    {
      colId: "cpa",
      headerName: 'CPA',
      field: 'cpa',



      type:['cellRendererSelector','cellStyle','comparatorCell']

    },

    {
      colId: "results_ga",
      headerName: 'Results GA',
      field: 'results_ga',

      type:['cellRendererSelector','cellStyle']

    },
    {
      colId: "results",
      headerName: 'Results',
      field: 'results',

      type:['cellRendererSelector','cellStyle']

    },
    {
      colId: "ctr",
      headerName: 'CTR', field: 'ctr',


      type:['cellRendererSelector','cellStyle','comparatorCell']
      // cellRendererSelector:this],

    },
    {
      colId: "cvr",
      headerName: 'CVR', field: 'cvr',


      type:['cellRendererSelector','cellStyle','comparatorCell'],

    },
    {
      colId: "cvr_ga",
      headerName: 'CVR GA', field: 'cvr_ga',

      type:['cellRendererSelector','cellStyle','comparatorCell'],

    },
    {
      colId: "thumbstop",
      headerName: 'Thumbstop',
      field: 'thumbstop',

      type:['cellRendererSelector','cellStyle','comparatorCell']

    },
    {
      colId: "retention",
      headerName: 'Retention',
      field: 'retention',

      type:['cellRendererSelector','cellStyle','comparatorCell']

    },
    {
      colId: "platform",
      headerName: 'Platform',
      field: 'platform',
      cellRenderer: this.platformCellRenderer,

      type:['cellRendererSelector','cellStyle']

    },
    {
      colId: "start_date",
      headerName: 'Start date', field: 'start_date',
      type: ['dateColumn', 'nonEditableColumn','cellRendererSelector','cellStyle'],


    },
    {
      colId: "end_date",
      headerName: 'Last active', field: 'end_date',
      type: ['dateColumn', 'nonEditableColumn','cellRendererSelector','cellStyle'],


    },
    {
      colId: "days_active",
      headerName: 'Days active', field: 'days_active',

      type:['cellRendererSelector','cellStyle']

    },
    {
      colId: "impressions",
      headerName: 'Impressions', field: 'impressions',

      type:['cellRendererSelector','cellStyle']

    },
    {
      colId: "link_clicks",
      headerName: 'Outbound clicks', field: 'link_clicks',

      type:['cellRendererSelector','cellStyle']

    },
    {
      colId: "video_3_sec_watched",
      headerName: '3 Sec watched', field: 'video_3_sec_watched',

      type:['cellRendererSelector','cellStyle']

    },
    {
      colId: "collection_title",
      headerName: 'Video name', field: 'collection_title',

      type:['cellRendererSelector','cellStyle']

    },
    {
      colId: "campaign_name",
      headerName: 'Campaign name', field: 'campaign_name',

      type:['cellRendererSelector','cellStyle']

    },
    {
      colId: "video_thruplay_watched",
      headerName: 'Video ThruPlay', field: 'video_thruplay_watched',

      type:['cellRendererSelector','cellStyle']

    },
    {
      colId: "account_name",
      headerName: 'Account', field: 'account_name',

      type:['cellRendererSelector','cellStyle']

    },

  ];

  frameworkComponents = {
    VidCellRenderer: VidCellComponent,
    AdNameComponent: AdNameComponent,
    CustomPinnedRowRendererComponent: CustomPinnedRowRendererComponent
  };

  defaultColDef = {
    sortable: true,
    flex: 1,
    minWidth: 135,
    resizable: true,
    enableCellTextSelection: true,
    sortingOrder: ['desc', 'asc'],
    leftAligned: {
      headerClass: 'ag-left-aligned-header',
      cellClass: 'ag-left-aligned-cell'
    }
  };
  constructor(private formatDataFactory: FormatDataFactory) {
    this.init();
  }
  updateIsRowSelected(rowSelected: any) {
    this._isRowSelected$.next(rowSelected)
  }
  columnTypes = {
    nonEditableColumn: { editable: false },
    firstColumn:{
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      pinned: true,
      autoHeight: true,
      minWidth: 150,
      lockPosition: true,
      cellRenderer: 'VidCellRenderer',
    },
    cellRendererSelector:{cellRendererSelector:this.cellSelectorRenderer},
    cellStyle:{cellStyle:this.cellStyleObject},
    comparatorCell:{comparator: this.costumeCompare,}



};
  init() {
    this.adDataTypes = {
      [biApiResponseKeys.AD_MANAGER_URL]: EAdDataType.LINK,
      [biApiResponseKeys.SPEND]: EAdDataType.CURRENCY,
      [biApiResponseKeys.AD_ID]: EAdDataType.ID,
      [biApiResponseKeys.ACCOUNT_NAME]: EAdDataType.STRING,
      [biApiResponseKeys.AD_ID]: EAdDataType.ID,
      [biApiResponseKeys.CAMPAIGN_NAME]: EAdDataType.STRING,
      [biApiResponseKeys.ADSET_NAME]: EAdDataType.STRING,
      [biApiResponseKeys.START_DATE]: EAdDataType.DATE,
      [biApiResponseKeys.END_DATE]: EAdDataType.DATE,
      [biApiResponseKeys.DAYS_ACTIVE]: EAdDataType.NUMBER,
      [biApiResponseKeys.RESULTS]: EAdDataType.NUMBER,
      [biApiResponseKeys.RESULTS_GA]: EAdDataType.NUMBER,
      [biApiResponseKeys.IMPRESSIONS]: EAdDataType.NUMBER,
      [biApiResponseKeys.WATCH_RATE]: EAdDataType.NUMBER,
      [biApiResponseKeys.LINK_CLICKS]: EAdDataType.NUMBER,
      [biApiResponseKeys.CPA]: EAdDataType.CURRENCY,
      [biApiResponseKeys.CPA_GA]: EAdDataType.CURRENCY,
      [biApiResponseKeys.CTR]: EAdDataType.PERCENTS,
      [biApiResponseKeys.CVR]: EAdDataType.PERCENTS,
      [biApiResponseKeys.CVR_GA]: EAdDataType.PERCENTS,
      [biApiResponseKeys.VIDEO_3_SEC_WATCHED]: EAdDataType.NUMBER,
      [biApiResponseKeys.VIDEO_THRUPLAY_WATCHED]: EAdDataType.NUMBER,
      [biApiResponseKeys.THUMBSTOP]: EAdDataType.PERCENTS,
      [biApiResponseKeys.RETENTION]: EAdDataType.PERCENTS,
      [biApiResponseKeys.QUALITY_RANKING_DAILY_AVG]: EAdDataType.DEFAULT,
      [biApiResponseKeys.ENGAGEMENT_RATE_RANKING_DAILY_AVG]: EAdDataType.DEFAULT,
      [biApiResponseKeys.CONVERSION_RATE_RANKING_DAILY_AVG]: EAdDataType.DEFAULT,
      [biApiResponseKeys.ID]: EAdDataType.ID,
      [biApiResponseKeys.COLLECTION_TITLE]: EAdDataType.DEFAULT,

    };
  }
  cellStyleObject (params){
    if(params.node.rowPinned){
    return  { 'padding-left': '0', 'border-left': 'none' }
    } else {
      return { 'padding-left': '17px', 'border-left': ' solid transparent' }
    }
  }
  cellSelectorRenderer(params){
    if (params.node.rowPinned) {
      return {
        component: 'CustomPinnedRowRendererComponent',
        // params: {
        //   coldata: { columnWidth: this.columnWidth },
        // },
      };
    } else {
      // rows that are not pinned don't use any cell renderer
      return undefined;
    }
  }
  platformCellRenderer(params) {
    const data = params.data;
    const platform: string[] = [];
    RefTableData.PLATFORM.forEach(({ platformName, icon }) => {
      if (data && data.platform && data.platform === platformName) {
        const htmlEl = `<img src="../../../assets/icons/${icon}.svg" width="16px" />`;
        platform.push(htmlEl);
      }
    });
    return platform.join(' ');
  }
  updateIsDoCompare(doCompare: boolean) {
    this._isDoCompare$.next(doCompare)
  }

  decoratedValue(value: any, key: string) {

    if (value === null) value = 0
    if (!this.adDataTypes[key]) {

      return value;
    }
    if (!this.formatDataFactory.numberStyleFrom(key)) {
      return value;
    }
    let formatStyle = this.formatDataFactory.numberStyleFrom(key);
    if ((value || value == 0) && this.adDataTypes[key] == EAdDataType.PERCENTS && !isNaN(Number(value))) {
      value = value.toFixed(5);
      let formatter = new Intl.NumberFormat("en-US", formatStyle);
      value = formatter.format(value);
      return value;

    } else if ((value || value == 0) && this.adDataTypes[key] == EAdDataType.CURRENCY && !isNaN(Number(value))) {
      value = value.toFixed(2);
      let formatter = new Intl.NumberFormat("en-US", formatStyle);
      value = formatter.format(value);
      return value;


    } else if ((value || value == 0) && this.adDataTypes[key] == EAdDataType.NUMBER && !isNaN(Number(value))) {

    }
    return value;
  }
  costumeCompare(valueA, valueB) {
    let rawA = valueA.replace("%", "").replace("$", "").replace(",", "");
    let rawB = valueB.replace("%", "").replace("$", "").replace(",", "");
    rawA = parseFloat(rawA);
    rawB = parseFloat(rawB);
    if (rawA == rawB) return 0;
    return (rawA > rawB) ? 1 : -1;
  }

  parseHelper(data: any, key: string) {
    let val: any = null;
    if (data && data[key]) {
      if (Array.isArray(data[key]) && data[key].length > 0) {
        val = data[key][0];
      } else if (typeof data[key] === 'string' || typeof data[key] == 'number') {
        val = data[key];
      }
    }
    val = this.decoratedValue(val, key)
    return val;
  }

  parseAd(data: IBiApiResponses.IAd): IBiApiResponses.IParsedAd {
    let parsedAd: IBiApiResponses.IParsedAd = {};


    parsedAd.creative_url = this.parseHelper(data, biApiResponseKeys.CREATIVE_URL);
    parsedAd.collection_title = data.collection_title && data.collection_title.length > 0 ? data.collection_title[0] : null;
    data.date.sort(function (a: string, b: string) {
      return (new Date(a) as any) - (new Date(b) as any);
    });
    parsedAd.date = data.date[0];
    parsedAd.start_date = parsedAd.date;
    parsedAd.end_date =
      data.date[data.date.length - 1];
    parsedAd.days_active = data.date.length;
    parsedAd.watch_rate = this.parseHelper(data, biApiResponseKeys.WATCH_RATE);
    parsedAd.spend = this.parseHelper(data, biApiResponseKeys.SPEND);
    parsedAd.impressions = this.parseHelper(data, biApiResponseKeys.IMPRESSIONS);
    parsedAd.cpa = this.parseHelper(data, biApiResponseKeys.CPA);
    parsedAd.cpa_ga = this.parseHelper(data, biApiResponseKeys.CPA_GA);
    parsedAd.cvr = this.parseHelper(data, biApiResponseKeys.CVR);
    parsedAd.cvr_ga = this.parseHelper(data, biApiResponseKeys.CVR_GA);
    parsedAd.ctr = this.parseHelper(data, biApiResponseKeys.CTR);
    parsedAd.results = this.parseHelper(data, biApiResponseKeys.RESULTS);
    parsedAd.results_ga = this.parseHelper(data, biApiResponseKeys.RESULTS_GA);
    parsedAd.link_clicks = this.parseHelper(data, biApiResponseKeys.LINK_CLICKS);
    parsedAd.video_3_sec_watched = this.parseHelper(data, biApiResponseKeys.VIDEO_3_SEC_WATCHED);
    parsedAd.video_thruplay_watched = this.parseHelper(data, biApiResponseKeys.VIDEO_THRUPLAY_WATCHED);
    parsedAd.retention = this.parseHelper(data, biApiResponseKeys.RETENTION);
    parsedAd.thumbstop = this.parseHelper(data, biApiResponseKeys.THUMBSTOP);
    parsedAd.account_id = this.parseHelper(data, biApiResponseKeys.ACCOUNT_ID) || data[biApiResponseKeys._ID][biApiResponseKeys.ACCOUNT_ID];
    let formetterAccountName = this.formatDataFactory.formatterOf(biApiResponseKeys.ACCOUNT_NAME);
    parsedAd.account_name = formetterAccountName.format(data.account_name) || formetterAccountName.format(data._id.account_name);
    parsedAd.platform = this.parseHelper(data, biApiResponseKeys.PLATFORM) || data._id.platform;
    parsedAd.ad_id = data.ad_id || data._id.ad_id;
    let keyID = Object.keys(data._id).find(key => key.includes('call_to_action_link') || key.includes(biApiResponseKeys.AD_ID)) || Object.keys(data._id).find(key => key.includes(biApiResponseKeys._ID));
    parsedAd.id = keyID ? data._id[keyID] : null;
    if (!parsedAd.id) {
      parsedAd.id = data.collection_id.length > 0 ? data.collection_id[0] : null;
    }
    parsedAd.ad_manager_url = this.parseHelper(data, biApiResponseKeys.AD_MANAGER_URL) || data._id.ad_manager_url;
    parsedAd.ad_manager_url = parsedAd.ad_manager_url + `&date=${parsedAd.start_date}_${parsedAd.end_date}`;
    parsedAd.campaign_name = this.parseHelper(data, biApiResponseKeys.CAMPAIGN_NAME) || data._id.campaign_name;
    parsedAd.adset_name = this.parseHelper(data, biApiResponseKeys.ADSET_NAME) || data._id.adset_name;
    parsedAd.ad_name = this.parseHelper(data, biApiResponseKeys.AD_NAME) || data._id.ad_name;
    //ret.image_url = this.value(data, "image_url");

    let { ad_id, account_id } = data._id;

    let imageUrl = THUMBNAIL_URL_PLACEHOLDER.replace(THUMBNAIL_URL_PLACEHOLDER_ACCOUNT, account_id).replace(THUMBNAIL_URL_PLACEHOLDER_AD, ad_id);

    parsedAd.image_url = imageUrl;

    parsedAd.quality_ranking_daily_avg = data.quality_ranking_daily_avg;
    parsedAd.engagement_rate_ranking_daily_avg = data.engagement_rate_ranking_daily_avg;
    parsedAd.conversion_rate_ranking_daily_avg = data.conversion_rate_ranking_daily_avg;
    return parsedAd;
  };

}
