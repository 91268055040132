import { Component, OnInit, Output, EventEmitter  } from '@angular/core';


@Component({
  selector: 'fm-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  constructor() { }
  selected: string = ""
  ngOnInit(): void {

  }

  choosedDate(evet: any) {

  }

}
