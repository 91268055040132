import { Component, OnInit, ChangeDetectorRef, AfterContentChecked, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import accountsSelectionData from '../../../models/mock/accounts-selection.json';
import platformSelectionData from '../../../models/mock/platform-selection.json';

interface IDropDownData {
  title: string,
  placeholderText: string,
  mandatory?: boolean,
  semicolon?: boolean,
  inputs: {
    key: string,
    text: string,
    icon?: string,
    isChecked?: boolean
  }[]
}

@Component({
  selector: 'fm-top-bar-filters',
  templateUrl: './top-bar-filters.component.html',
  styleUrls: ['./top-bar-filters.component.scss']
})
export class TopBarFiltersComponent implements OnInit {

  accounts: IDropDownData = accountsSelectionData;
  platform: IDropDownData = platformSelectionData;


  dropdownsDataGetters: Array<() => (string[] | undefined)>;


  constructor(private dataService: DataService) {

  }

  startDate: any;
  endDate: any;
  ngOnInit(): void {

    this.dropdownsDataGetters = [this.dataService.getAccounts, this.dataService.getPlatforms];
  }



  handleSearchEnbaled() {

    let numOfSelectedDropdowns = this.dropdownsDataGetters.reduce((acc, getter) => {
      let arr = getter.apply(this.dataService);
      let arrLength = arr?.length || 0;
      acc += arrLength > 0 ? 1 : 0;
      return acc;
    }, 0)

    this.dataService.updateSearchEnabled(numOfSelectedDropdowns == this.dropdownsDataGetters.length);
  }

  accountsSelectChanged(val: any[]) {
    console.log('Selected accounts: ', val);

    this.dataService.setAccounts(val);
    this.handleSearchEnbaled();
  }

  platformSelectChanged(val: any[]) {
    console.log('Selected platforms: ', val);

    this.dataService.setPlatforms(val);
    this.handleSearchEnbaled();
  }

  startDateChanged(val: string) {
    console.log('Selected platforms: ', val);
    this.dataService.setStartDate(val);
  }

  endDateChanged(val: string) {

    console.log('Selected platforms: ', val);
    this.dataService.setEndDate(val);
  }

}
