<fm-filters></fm-filters>


<fm-table (vidSrc)="setVidSrc($event)"
[_frameworkComponents] = 'frameworkComponents'
[_defaultColsArr]= 'defaultColsArr'
[_defaultColDef]= 'defaultColDef'
[_columnTypes]='columnTypes'
></fm-table>

<jw-modal id="custom-modal-7">
    <img (click)="closeModal('custom-modal-7')" src="../../../assets/icons/close-modal-btn.svg" class="close-modal-btn"/>
  <fm-video-player [src]="vidSrc" [title]="vidName" [width]="550"></fm-video-player>
</jw-modal>
