import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { AiRecommendComponent } from './pages/ai-recommend/ai-recommend.component';
import { BiAdsComponent } from './pages/bi-ads/bi-ads.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { LayoutComponent } from './components/layout/layout.component';
import { HeaderComponent } from './components/header/header.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FiltersComponent } from './components/filters/filters.component';

import { TopBarFiltersComponent } from './components/filters/top-bar-filters/top-bar-filters.component';
import { SearchBarFiltersComponent } from './components/filters/search-bar-filters/search-bar-filters.component';
import { AuthInterceptor as AuthInterceptor } from './interceptors/auth.interceptor';
import {
  DaterangepickerDirective,
  NgxDaterangepickerMd,
} from 'ngx-daterangepicker-material';
import { FormsModule } from '@angular/forms';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { PlayerComponent } from './components/player/player.component';
import { AiScoreAdComponent } from './components/ai-score-ad/ai-score-ad.component';
import { AiScorePercentComponent } from './components/ai-score-percent/ai-score-percent.component';
import { AiFilterBarComponent } from './components/ai-filter-bar/ai-filter-bar.component';
import { AiSuggestionsComponent } from './components/ai-suggestions/ai-suggestions.component';
import { AgGridModule } from 'ag-grid-angular';
import { TableComponent } from './components/table/table.component';
import { FileSizePipe } from './utils/file-size.pipe';

import { FooterComponent } from './components/footer/footer.component';
import { PlyrModule } from 'ngx-plyr';
import { VideoPlayerComponent } from './components/video-player/video-player.component';

import { DateRangeDropdownComponent } from './date-range-dropdown/date-range-dropdown.component';

import { ModalComponent } from './components/modal/modal.component';
import { ModalService } from './services/modal.service';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ProgressComponent } from './pages/ai-recommend/components/progress/progress.component';
import { DropdownComponent } from './components/common-ui/dropdown/dropdown.component';
import { UploadContainerComponent } from './pages/upload-page-example/component';
import { AnimatedNumbersComponent } from './components/animated-numbers/animated-numbers.component';
import { LabelsDropdownComponent } from './components/common-ui/labels-dropdown/labels-dropdown.component';
import { AnalyticsConfigComponent } from './pages/ai-recommend/components/analytics-config/analytics-config.component';
import { AiScoreComponent } from './components/ai-score/ai-score.component';
import { TestAiScoreComponent } from './pages/tests/test-ai-score/test-ai-score.component';
import { RecommendationsComponent } from './pages/ai-recommend/components/recommendations/recommendations.component';
import { UploadComponent } from './pages/ai-recommend/components/upload/upload.component';
import { ConvertToSpacesPipe } from './shared/convert-to-spaces.pipe';
import { CurrencyPipe, DecimalPipe  } from '@angular/common';
import { SuggestionItemComponent } from './suggestion-item/suggestion-item.component';
import { VidCellComponent } from './components/table/vid-cell/vid-cell.component';
import { MinMaxTrayComponent } from './components/min-max/min-max-tray/min-max-tray.component';
import { MinMaxComponent } from './components/min-max/min-max.component';
import { MinMaxInputComponent } from './components/min-max/min-max-input/min-max-input.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { ClickElsewhereDirective } from './directive/click-elsewhere.directive';
import { AdNameComponent } from './components/table/ad-name/ad-name.component';
import { CustomPinnedRowRendererComponent } from './components/table/custom-pinned-row-renderer/custom-pinned-row-renderer.component';
import { TooltipModule } from 'ng2-tooltip-directive';

@NgModule({
  declarations: [
    AppComponent,
    SideBarComponent,
    BiAdsComponent,
    AiRecommendComponent,
    PageNotFoundComponent,
    LayoutComponent,
    HeaderComponent,
    FiltersComponent,
    TopBarFiltersComponent,
    SearchBarFiltersComponent,
    DateRangeComponent,
    PlayerComponent,
    AiScoreAdComponent,
    AiScorePercentComponent,
    AiFilterBarComponent,
    AiSuggestionsComponent,
    TableComponent,
    FileSizePipe,
    FileUploadComponent,
    UploadContainerComponent,
    FooterComponent,
    VideoPlayerComponent,
    ModalComponent,
    ProgressComponent,
    DropdownComponent,
    DateRangeDropdownComponent,
    ModalComponent,
    AnalyticsConfigComponent,
    AnimatedNumbersComponent,
    LabelsDropdownComponent,
    AiScoreComponent,
    TestAiScoreComponent,
    RecommendationsComponent,
    UploadComponent,
    ConvertToSpacesPipe,
    SuggestionItemComponent,
    VidCellComponent,
    MinMaxTrayComponent,
    MinMaxComponent,
    MinMaxInputComponent,
    ClickElsewhereDirective,
    AdNameComponent,
    CustomPinnedRowRendererComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    AgGridModule.withComponents(),
    PlyrModule,
    FormsModule,
    DigitOnlyModule,
    TooltipModule
  ],

  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    DaterangepickerDirective, ModalService,CurrencyPipe,DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule {}
