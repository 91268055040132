import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { ISuggestions } from '../../interfaces/suggestion.interface';
import { SuggestConstants } from "./constants";

@Component({
  selector: 'fm-ai-suggestions',
  templateUrl: './ai-suggestions.component.html',
  styleUrls: ['./ai-suggestions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AiSuggestionsComponent implements OnInit {

  @Input() data: any;
  suggestConstants = SuggestConstants;

  constructor() { }

  ngOnInit() {}

  get body() {
    // suggestConstants.SUGGESTION_BODY
    const varHolder = /<value %>/i;
    return this.suggestConstants.SUGGESTION_BODY.replace(varHolder, `<span class="accent">${this.data.possibleScoreValue}</span>`);
  }

}
