import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import RefTableData from 'src/app/common/RefTableData';
import { DataService } from 'src/app/services/data.service';
import { TableService } from 'src/app/services/table.service';
import { ColumnApi, GridApi, Module, ColDef, ColGroupDef, GridReadyEvent, CellClickedEvent, GridOptions } from 'ag-grid-community';
import { IBiApiResponses } from '../../interfaces'
import { DecimalPipe } from '@angular/common';
import accountsSelectionData from '../../models/mock/accounts-selection.json';
import { ModalService } from 'src/app/services/modal.service';
import { DataLoadState, itemType } from 'src/app/enums/table-component.enum';


@Component({
  selector: 'fm-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnDestroy  {

  frameworkComponents:{}
  defaultColsArr:{[key: string]: unknown}[]
  defaultColDef:{}
  columnTypes:{}

  @Input() set _frameworkComponents(value:{}){
    this.frameworkComponents = value
  }
  @Input() set _defaultColsArr(value:[]){
    this.defaultColsArr = value
  }
  @Input() set _defaultColDef(value:[]){
    this.defaultColDef= value
  }
  @Input() set _columnTypes(value:{}){
    this.columnTypes= value
  }

  accounts = accountsSelectionData;

  showPinnedRow: Array<string | undefined>;
  declare window: any;
  columnDefs: (ColDef | ColGroupDef)[];
  private gridColumnApi: ColumnApi;
  public modules: Module[] = [];
  pinnedTopRowData: any[] = []

  @Output() vidSrc = new EventEmitter<string>();

  selection = new BehaviorSubject<Array<number>>([]);

  srcTableMsg: string = RefTableData.TABLE_EMPTY_IMAGE;
  srcNoSearchResult: string = RefTableData.TABLE_NO_SEARCH_RESULT;
  vidBassUrl: string = RefTableData.VID_BASS_URL
  srcTableSearchMsg: string = RefTableData.TABLE_EMPTY_SEARCH_iMAGE;

  creativeUrl: string = '';
  isDoCompare: boolean = false;
  rowCompare: Array<{}> = [{}];
  rowDataOrg: any;
  columnsState: any;
  loading: boolean

  rowData: any[] = [];
  showGrid: boolean = false;

  private api: GridApi;
  private columnApi: ColumnApi;
  gridOptions: GridOptions;

  columnWidth: number
  /*
    for decimalPipe rules
  */
  roundRules = '1.0-2';

  localCopyOfSelected;
  

  initialDataFetched = DataLoadState.initialState;
  DataLoadState = DataLoadState;

  searchWasClicked = false;
  noRowDataFetchedAfterSearch = false;
  subscriptionForAll = new Subscription();
  

  constructor(private dataService: DataService,
    private tableService: TableService,
    private decimalPipe: DecimalPipe,private modalService:ModalService, ) { }

  ngOnInit(): void {
    this.subscriptionForAll.add(
      this.dataService.searchButtonClicked$.subscribe((val) => {
      this.searchWasClicked = val;
    })
    );

    if (this.rowData.length === 0) this.showGrid = false;
    this.subscriptionForAll.add(
      this.dataService.biAdData$.subscribe(res => {
        this.initialDataFetched += 1;
        let ads = res.map(ad => this.tableService.parseAd(ad));
        this.rowData = ads;
        this.rowDataOrg = [...this.rowData];
        if (this.rowData.length > 0) {
          this.showGrid = true;
          if (this.showPinnedRow.length === 1 ) {
  
            this.pinnedTopRowData = this.buildToRowData()
          } else {
            this.pinnedTopRowData = ([])
            //this.api.setPinnedTopRowData([])
          }
        } else {
          if (this.searchWasClicked) {
            this.noRowDataFetchedAfterSearch = true;
          }
          this.showGrid = false;
          this.pinnedTopRowData = ([]);
        }
      })
    );

    this.subscriptionForAll.add(this.tableService.isDoCompare$.subscribe(res => {
      this.isDoCompare = res
      this.doCompare(res);
      setTimeout(() => {
        this.checkedCheckedboxes();
      });
    }));
    this.subscriptionForAll.add(this.dataService.showPinnedRow$.subscribe((res) => {
      this.showPinnedRow = res;
    }));
    this.subscriptionForAll.add(this.dataService.loading$.subscribe(res => {
      this.loading = res;

      if (this.loading) {
        // this.openModal('custom-modal-2');
      }
    }));
  
    this.initColumnDefs();
    this.setGridOptions();
  }

  getRoundNumber(num: number): string | null {
    return this.decimalPipe.transform(num, this.roundRules) ?? '0';
  }
  search(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].key === nameKey) {
        return myArray[i].text;
      }
    }
  }
// rowData: Array<IBiApiResponses.IParsedAd>
  buildToRowData(): Array<IBiApiResponses.IParsedAd> {
    let accountName: string = this.search(this.showPinnedRow[0], this.accounts.inputs);

    let data: IBiApiResponses.IParsedAd = {}
    const prefix = {
      total: 'Total',
      avg: 'Avg. ',
      $: '$',
      percent: '%'
    }
    data = {
      image_url: '',
      ad_name: '',
      spend: '',
      cpa_ga: '',
      cpa: '',
      results_ga: 0,
      results: 0,
      ctr: '',
      cvr: '',
      cvr_ga: '',
      thumbstop: '',
      retention: '',
      platform: '',
      start_date: '',
      end_date: '',
      days_active:'',
      impressions: '',
      link_clicks: '',
      video_3_sec_watched: '',
      collection_title: '',
      campaign_name: '',
      video_thruplay_watched: '',
      account_name: ''
    }

    let dataLength: number = this.rowData.length;

    // totals
    let spendValue: number = 0;
    let Results: number = 0;
    let Results_GA: number = 0;
    // average
    let CPA: number = 0;
    let CPA_GA: number = 0;
    let CTR: number = 0;
    let CVR: number = 0;
    let CVR_GA: number = 0;
    let THUMBSTOP: number = 0;
    let RETENTION: number = 0;
    let ACCOUNT_TITLE: {} = {};
    let CPM: number = 0; // TBD

    this.rowData.forEach(rowItem => {
      spendValue = this.sumTotale(rowItem.spend, spendValue, itemType.STR);
      Results = this.sumTotale(rowItem.results, Results, itemType.NUM);
      Results_GA = this.sumTotale(rowItem.results_ga, Results_GA, itemType.NUM);
      CPA = this.sumTotale(rowItem.cpa, CPA, itemType.STR);
      CPA_GA = this.sumTotale(rowItem.cpa_ga, CPA_GA, itemType.STR);
      CTR = this.sumTotale(rowItem.ctr, CTR, itemType.STR);
      CVR = this.sumTotale(rowItem.cvr, CVR, itemType.STR);
      CVR_GA = this.sumTotale(rowItem.cvr_ga, CVR_GA, itemType.STR);
      THUMBSTOP = this.sumTotale(rowItem.thumbstop, THUMBSTOP, itemType.STR);
      RETENTION = this.sumTotale(rowItem.retention, RETENTION, itemType.STR);
    })

      data.spend      = { value: (prefix.$ + (this.getRoundNumber(spendValue))), title: prefix.total, colId: 'spend' };
      data.results    = { value: Results, title: prefix.total, colId: 'results' };
      data.results_ga = { value: Results_GA, title: prefix.total, colId: 'results_ga' };
      data.cpa        = { value: (prefix.$ + (this.getRoundNumber(CPA / dataLength))), title: prefix.avg, colId: 'cpa' };
      data.cpa_ga     = { value: (prefix.$ + (this.getRoundNumber(CPA_GA / dataLength))), title: prefix.avg, colId: 'cpa_ag' };
      data.ctr        = { value: ((this.getRoundNumber(CTR / dataLength)) + prefix.percent), title: prefix.avg, colId: 'ctr' };
      data.cvr        = { value: ((this.getRoundNumber(CVR / dataLength)) + prefix.percent), title: prefix.avg, colId: 'cvr' };
      data.cvr_ga     = { value: ((this.getRoundNumber(CVR_GA / dataLength)) + prefix.percent), title: prefix.avg, colId: 'cvr_ga' };
      data.thumbstop  = { value: ((this.getRoundNumber(THUMBSTOP / dataLength)) + prefix.percent), title: prefix.avg, colId: 'thumbstop' };
      data.retention  = { value: ((this.getRoundNumber(RETENTION / dataLength)) + prefix.percent), title: prefix.avg, colId: 'retention' }
      data.image_url  = { value: ('Showing ' + accountName + ' totals'), title: '', colId: 'image_url' };
      data.ad_name    = { value: '', title: '', colId: 'ad_name' };

    return [data]
  }
  sumTotale(valueItem, itemSum, type) {
    if (type === itemType.NUM) itemSum += valueItem;
    if (type === itemType.STR) {
      let value: number = Number(valueItem.replace('$', '').replace(',', '').replace('%', ''))
      itemSum += value;
    }
    return itemSum
  }

  initColumnDefs() {
    let currentCollState: any[] = [];
    let colData: string | null = localStorage.getItem('myColumnState')
    if (colData) {
      this.columnsState = JSON.parse(colData);
      this.columnsState.forEach((value, index) => {
        let col = (this.defaultColsArr.find(x => x.colId === value.colId))
        if (col) col.sort = value.sort
        currentCollState.push(this.defaultColsArr.find(x => x.colId === value.colId));
      });
      this.columnDefs = currentCollState;
    } else {
      this.columnDefs = this.defaultColsArr;
    }
  }
  setGridOptions() {
    this.gridOptions = {
      paginationPageSize: 30,
      onColumnMoved: this.onColumnMoved,
      defaultColDef: this.defaultColDef,
      enableCellTextSelection: true
    }
  }

  public onGridReady(params: GridReadyEvent) {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  onSelectionChange($event: any) {
    let selectedNodes = this.api.getSelectedNodes();
    this.localCopyOfSelected = this.api.getSelectedNodes();
    let selectedData: number[] = selectedNodes.map(node => node.data.ad_id);
    this.selection.next(selectedData);
    if (this.selection.value.length > 1) {
      this.tableService.updateIsRowSelected(true);
    } else {
      this.tableService.updateIsRowSelected(false);
    }
  }

  doCompare(value: boolean) {
    if (value) {
      const rowsCompare = this.rowData.filter(({ ad_id }) => this.selection.value.includes(ad_id))
      this.rowData = rowsCompare;
    } else if (!value) {
      this.rowData = this.rowDataOrg
    }
  }

  checkedCheckedboxes() {
    this.gridOptions.api?.forEachNode((node) => {
      this.localCopyOfSelected.forEach(nodeSelected => {
        if (node.data.ad_id[0] === nodeSelected.data.ad_id[0]) {
          node.setSelected(true);
        }
      });
     });
  }

  onCellClicked(event: CellClickedEvent) {
    if (event.colDef.field === 'image_url') {
      this.creativeUrl = event.data.creative_url;
      let videoSrc: string = `${this.vidBassUrl}${this.creativeUrl}`
      this.vidSrc.emit(videoSrc);
    }
  }

  onColumnMoved(params) {
    let state = params.columnApi.getColumnState();
    let columnState = JSON.stringify(state);
    localStorage.setItem('myColumnState', columnState);
  }

  onSortStateEvent(params) {
    let state = params.columnApi.getColumnState();
    let columnState = JSON.stringify(state);
    localStorage.setItem('myColumnState', columnState);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  rowClicked(event: any) {
    // console.log('rowClicked',event.data);
  }

  addGlobalListener(listener: Function): void {
    // console.log(' listener : ', listener);
  };

  onColumnResized(params) {
    if (params.source === 'uiColumnDragged' && params.finished) {
      this.columnWidth = params.column.actualWidth
    }
  }

  ngOnDestroy(): void {
    this.subscriptionForAll.unsubscribe();
  }

}