import { Injectable } from '@angular/core';
import { interval, Observable, of, throwError } from 'rxjs';
import { HttpHandlerService } from './http-handler.service';
import { IBiApiRequests } from '../interfaces/bi-api-requests.interface';
import { IBiApiResponses } from './../interfaces/bi-api-responses.interface';
import { ILogin } from '../interfaces/login-api.interface';
import { catchError, delay, mergeMap, repeat, retry, retryWhen, take, tap } from 'rxjs/operators';
import { ErrorType } from '../enums/error.enum';
import { IAiApiRequests } from '../interfaces/ai-api-requests.interface';
import { IAiApiResponses } from '../interfaces/ai-api-responses.interface';
import { AI_API_HOST } from '../common/constants';
import { IVideoPayload } from '../interfaces/ai-video-sqs.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends HttpHandlerService {


  fetchBiResults(body: IBiApiRequests.IApiRequestAdsPayload): Observable<IBiApiResponses.IApiResponseAds> {
    const urlPath = '/performance/ad';
    return this.post(urlPath, body);
    //return this.mockData.getAds();

  }

  fetchFiltersOptions(body: IBiApiRequests.IApiRequestFiltersPayload): Observable<IBiApiResponses.IApiResponseFilter> {
    const urlPath = '/performance/filters';
    return this.post(urlPath, body);
    //return this.mockData.getFilters();
  }

  login(body: ILogin.LoginApiRequest): Observable<ILogin.LoginApiResponse> {
    const url = 'https://client.first.media:8080/api/rubix/loginn';
    //return this.post(urlPath, body);

    return this.http.post(url, body, { headers: this.headers }) as Observable<ILogin.LoginApiResponse>;
  }

  checkForVideoAnalyzeStatus(body: IAiApiRequests.IUploadAnalyzePayload) {
    const url = `${AI_API_HOST}/video/uploaded/analyze/status?video_id=${body.video_id}`;


    return this.http.get(url, { headers: this.headers }) as Observable<{
      analyzed: boolean
    }>;

  }

  startUploadAnalyze(body: IAiApiRequests.IUploadAnalyzePayload) {
    const url = `${AI_API_HOST}/video/uploaded/analyze?video_id=${body.video_id}`;
    return this.http.get(url, { headers: this.headers }) as Observable<any>;

  }

  runVideoOptimizations(body: IAiApiRequests.IUploadOptimizePayload) {
    const url = `${AI_API_HOST}/optimizations?video_id=${body.video_id}&score_type=${body.score_type}&black_list=${body.black_list.join(',')}`;
    return this.http.get(url, { headers: this.headers }) as Observable<any>;

  }

  getVideoScore(body: IAiApiRequests.IScorePayload): Observable<IAiApiResponses.IAiApiResponse> {
    const url = `${AI_API_HOST}/score`;
    //return this.post(urlPath, body);

    return this.http.post(url, body, { headers: this.headers }) as Observable<IAiApiResponses.IAiApiResponse>;
  }






  pollReq(observable: Observable<any>) {
    let count = 0
    let limit = 180
    let milSecods = 1000
    return observable.pipe(catchError(e => throwError(e)), retryWhen((errors: Observable<any>) =>
      errors.pipe(delay(milSecods), take(limit), tap((() => {
        count++;
        if (count == limit) {
          throw this.errorService.create({ type: ErrorType.POLLING_ENDED, message: 'poll ended with no  results' })
        }

      })
      ))));

  }

  async sqsSendSqlMessege(body: IVideoPayload) {
    const url = environment.WEBAPP_BACKEND_BASEURL + "api/sqs/"
    return this.http.post(url, body, { headers: this.headers });
  };


}
