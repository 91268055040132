<div class="sidebar-container">
  <div class="column sidebar-header">
    <div class="app-title">CRAIG</div>
    <span class="subtitle">Connect Creativity and Performance</span>
  </div>
  <div class="column sb-links">
    <!-- <button  routerLink="/bi">
      <img src="../../../../assets/icons/ads-overview-dim-sb-icon.svg" alt="filter-by" class="btn-icon" />
      <span class="link">Ads Overview</span>
    </button> -->
    <!-- <button class="sb-btn" routerLink="/bi">
      <img src="../../../../assets/icons/ads-overview-dim-sb-icon.svg" alt="filter-by" class="btn-icon" />
      <span class="link">Ads Overview</span>
    </button> -->
    <button class="sb-btn sb-btn__ads" routerLink="/bi" routerLinkActive="sb-btn__ads--active">
      <img src="../../../../assets/icons/ads-sb-icon.svg" alt="filter-by" class="btn-icon" />
      <span class="link">Ads Analysis</span>
    </button>

    <div class="sb-btn__wrapper">
      <button class="sb-btn sb-btn__ai" routerLink="/ai" routerLinkActive="sb-btn__ai--active">
        <img src="../../../../assets/icons/ai-studio-sb-icon.svg" alt="filter-by" class="btn-icon" />
        <span class="link">AI Optimization</span>
      </button>

      <button class="sb-btn__button" (click)="newPredict()" *ngIf="aiVisited">
        <img src="../../../../assets/icons/plus.svg">
      </button>
    </div>

  </div>
  <!-- <div class="separator">
    <div class="h-separator"></div>
  </div> -->

    <!-- <div class="column sb-links">
    <button class="sb-btn" routerLink="/bi">
      <img src="../../../../assets/icons/user-profile-sb-Icon.svg" alt="filter-by" class="btn-icon" />
      <span class="link">User Profile</span>
    </button>
    <button class="sb-btn" routerLink="/bi">
      <img src="../../../../assets/icons/config-sb-icon.svg" alt="filter-by" class="btn-icon" />
      <span class="link">Configuration</span>
    </button>
    <button class="sb-btn" routerLink="/bi">
      <img src="../../../../assets/icons/notifaction-sb-icon.svg" alt="filter-by" class="btn-icon" />
      <span class="link">Notifications</span>
    </button>

  </div> -->
  <div class="sb-footer">
    <span class="powered-by">powered by</span>
    <img src="../../../../assets/images/first-media-img.png" alt="filter-by" class="btn-icon" />
  </div>
</div>
