import { Component, OnDestroy } from '@angular/core';

import { AppConstants } from 'src/app/common/constants';
import { ErrorType } from 'src/app/enums/error.enum';

import { IinputData } from 'src/app/interfaces/inputs.interface';

import { ErrorService } from 'src/app/services/error.service';

import videoAnalyzingResponseData from '../../models/mock/video-analyzing-summary.json';
import accountsSelectionData from '../../models/mock/accounts-selection.json';
import metricSelectionData from '../../models/mock/metric-selection.json';
import labelsData from '../../models/mock/labels-selection.json';
import { AiDataService } from 'src/app/services/ai-data.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { IVideoFileData } from 'src/app/interfaces/video-file-data.interface';

@Component({
  selector: 'app-ai-recommend',
  templateUrl: './ai-recommend.component.html',
  styleUrls: ['./ai-recommend.component.scss'],
})
export class AiRecommendComponent implements OnDestroy {

  disabled: boolean = true;
  MAX_VIDEO_SIZE = AppConstants.MAX_VIDEO_SIZE;
  videoAnalyzingResponseData = videoAnalyzingResponseData;
  videoUrl:string =''
  showRecs: boolean = false;
  disabledSelect: boolean = false;
  accounts: IinputData.accountsInputData = accountsSelectionData;
  metrics: IinputData.metricsInputData = metricSelectionData;
  labels: IinputData.labelsData = labelsData;
  subscription: Subscription | null
  showStatistics:boolean = true;
  loadedRecomendation: number = 0;

  videoFileData: IVideoFileData.videoData
  aiData: any
  suggestionsData: any
  scoreData:any
  videoLocalName:string = '';
  aiVisited:boolean;

  constructor(private errorService: ErrorService, private aiDataService: AiDataService, private dataService: DataService) { }

  ngOnInit() {
    this.dataService.updatePageTitle(AppConstants.AI_PAGE_TITLE);
    if(this.aiDataService.aiCurrentData !== undefined){
      this.aiData = this.aiDataService.aiCurrentData
      this.suggestionsData = this.aiData.suggestions
      this.scoreData = this.aiData.scores
      this.videoUrl = this.aiData.videoData.remoteUrl
      this.aiVisited  = true;
    }

    this.subscription = this.aiDataService.aiData$.subscribe((data) => {
      this.aiData = data
      this.suggestionsData = this.aiData.suggestions
      this.scoreData = this.aiData.scores
      this.videoUrl = this.aiData.videoData.remoteUrl
      this.aiDataService.updateAiVisited(true)
      this.onAnalyzeEnd();
    })
    this.subscription = this.aiDataService.aiVisited$.subscribe( res => {
      this.aiVisited = res;
    })
  }


  finishVideoUpload(videoFileData: IVideoFileData.videoData) {
    this.onAnalyzeStart();
    if (videoFileData?.remoteFileName) {
      this.videoFileData = videoFileData;
      this.videoLocalName = videoFileData.localFileName
      this.aiDataService.runVideoAnalyze(videoFileData);
    } else {
      throw this.errorService.create({ type: ErrorType.NO_VIDEO_ID, message: "no video id found" });
    }
  }

  onAnalyzeStart(): void {
    this.disabledSelect = true;
  }

  onAnalyzeEnd(): void {
    this.videoUrl = this.videoFileData.remoteUrl;
    this.aiDataService.updateAiVisited(true);

    // const interval = setInterval(() => {

    //   this.loadedRecomendation++;
    //   if (this.loadedRecomendation > this.recomendations.length) {
    //     clearInterval(interval);
    //   }
    // }, 500);
  }

  ngOnDestroy() {
    console.log(2222);
     this.subscription?.unsubscribe();
  }
}

