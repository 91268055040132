import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { NO_PERMISSIONS } from '../common/constants';
import { ELogin } from '../enums/login.enum';
import { IBiApiResponses } from '../interfaces/bi-api-responses.interface';


export class UserRoles {
  public static ADMIN = "admin";
  public static USER = "user";
  public static NONE = "";
  static ROLE_KEY = "ROLE"

  public static setRole(val: string) {
    localStorage.setItem(UserRoles.ROLE_KEY, val);
  }

  public static getRole() {
    return localStorage.getItem(UserRoles.ROLE_KEY);
  }

}

export class UserLoginState {
  static stateKey = 'STATE';



  public static isLoggedIn(): boolean {
    return localStorage.getItem(UserLoginState.stateKey) === ELogin.LOGGED_IN
  }

  public static login() {
    localStorage.setItem(UserLoginState.stateKey, ELogin.LOGGED_IN);
  }

  public static logout() {
    localStorage.setItem(UserLoginState.stateKey, ELogin.LOGGED_OUT);
  }

}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLogin = false;
  roleAs: string | null = UserRoles.NONE;


  constructor() { }

  login(value: string = UserRoles.NONE) {
    this.isLogin = true;
    this.roleAs = value;
    UserLoginState.login();
    UserRoles.setRole(this.roleAs);

    return of({ success: this.isLogin, role: this.roleAs });
  }

  logout() {
    this.isLogin = false;
    this.roleAs = '';
    UserLoginState.logout();
    UserRoles.setRole(UserRoles.NONE);

    return of({ success: this.isLogin, role: '' });
  }

  isLoggedIn(res: IBiApiResponses.IApiResponseAds | null = null) {
    // if (res && (res as IBiApiResponses.IApiResponseAds).body && res.body as string === NO_PERMISSIONS) {
    //   this.isLogin = false;
    //   return this.isLogin;
    // }
    this.isLogin = UserLoginState.isLoggedIn();
    return this.isLogin;
  }



  getRole() {
    this.roleAs = UserRoles.getRole()
    return this.roleAs;
  }

}