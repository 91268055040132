import { Injectable } from '@angular/core';
import { S3 } from 'aws-sdk';

import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AWS_REGION_DEFAULT, HTTP_UPLOAD_PROGRESS } from '../common/constants';
import { EContainerEvents } from '../enums/file-upload.enum';
import { fnProgress } from '../interfaces/file-upload.interface';
import { FileObject } from '../models/file.model';
import { S3Factory } from '../utils/s3';




@Injectable({
  providedIn: 'root'
})
export class S3UploadService {

  // Observable string sources
  private uploadContainerEventSource = new Subject<EContainerEvents>();
  private fileUploadEventSource = new Subject<FileObject>();
  fileName!: string;

  // Observable string streams
  uploadContrainerEvent$ = this.uploadContainerEventSource.asObservable();
  fileUploadEvent$ = this.fileUploadEventSource.asObservable();

  private defaultRegion: string;

  constructor() {
    this.defaultRegion = AWS_REGION_DEFAULT;
  }



  // Upload status updates
  publishUploadContainerEvent(event: EContainerEvents) {
    this.uploadContainerEventSource.next(event);
  }

  publishFileUploadEvent(file: FileObject) {
    this.fileUploadEventSource.next(file);
  }

  setRegion(region: string) {
    this.defaultRegion = region;
  }

  private preparePutObjectRequest(file: File, fileName: string): S3.Types.PutObjectRequest {
    const now = new Date();
    const obj = {
      Key: 'craig-uploaded-videos/upload_' + fileName + "." + file.name.split('.').reverse()[0],
      Bucket: environment.s3Config.bucket,
      Body: file,
      ContentType: file.type
    };
    return obj;

  }

  upload(file: File, progressCallback: fnProgress, region: string | null, fileName: string) {

    region = region || this.defaultRegion;
    let payload = this.preparePutObjectRequest(file, fileName);
    const s3Upload = S3Factory.getS3(region).upload(payload);
    s3Upload.on(HTTP_UPLOAD_PROGRESS, this.handleS3UploadProgress(progressCallback));
    s3Upload.send(this.handleS3UploadComplete(progressCallback));
    return s3Upload;
  }

  private handleS3UploadProgress
    (progressCallback: fnProgress) {
    let uploadStartTime = new Date().getTime();
    let uploadedBytes = 0;
    return (progressEvent: S3.ManagedUpload.Progress) => {
      const currentTime = new Date().getTime();
      const timeElapsedInSeconds = (currentTime - uploadStartTime) / 1000;
      if (timeElapsedInSeconds > 0) {
        const speed = (progressEvent.loaded - uploadedBytes) / timeElapsedInSeconds;
        const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        if (progress != 100) {
          progressCallback(undefined, progress, speed);
        }
        uploadStartTime = currentTime;
        uploadedBytes = progressEvent.loaded;
      }
    };
  }

  private handleS3UploadComplete(
    progressCallback: fnProgress) {
    return (error: Error, data: S3.ManagedUpload.SendData) => {
      if (error) {
        progressCallback(error, undefined, undefined);
      } else {
        progressCallback(error, 100, undefined);
      }
    };
  }

  cancel(s3Upload: S3.ManagedUpload) {
    s3Upload.abort();
  }
}
