import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAiApiRequests } from 'src/app/interfaces/ai-api-requests.interface';
import { IinputData } from 'src/app/interfaces/inputs.interface';
import { AiDataService } from 'src/app/services/ai-data.service';

@Component({
  selector: 'fm-analytics-config',
  templateUrl: './analytics-config.component.html',
  styleUrls: ['./analytics-config.component.scss'],
})
export class AnalyticsConfigComponent implements OnInit {

  constructor(private dataService: AiDataService) { }

  chosenAccounts: string[] = [];
  chosenExludedLabels: string[] = [];
  chosenMetrics: string[] = [];;

  @Input() disabled = false;
  @Input() accountsData: IinputData.accountsInputData;
  @Input() metricData: IinputData.metricsInputData;
  @Input() labelsData: IinputData.labelsData;
  @Output() selectedItemsEvent = new EventEmitter<boolean>();

  initChosens(data) {
    return data.inputs.filter(input => input.isChecked).map(input => input.key) as string[];


  }
  ngOnInit(): void {
    this.chosenAccounts = this.initChosens(this.accountsData);
    this.dataService.setAccounts(this.chosenAccounts);
    this.chosenExludedLabels = this.initChosens(this.labelsData);
    this.dataService.setLabels(this.chosenExludedLabels);
    this.chosenMetrics = this.initChosens(this.metricData);
    this.dataService.setScoreType(this.chosenMetrics as IAiApiRequests.IscoreType[]);
    this.emitValidity();
  }

  selectVertical(event: any, select: 'account' | 'label' | 'metric'): void {
    switch (select) {
      case 'account':
        this.chosenAccounts = event;
        this.dataService.setAccounts(event);
        break;
      case 'label':
        this.chosenExludedLabels = event;
        this.dataService.setLabels(event)
        break;
      case 'metric':
        this.chosenMetrics = event;
        this.dataService.setScoreType(event)
        break;
      default:
        break;
    }
    this.emitValidity();
  }

  emitValidity(): void {
    if (
      this.chosenAccounts.length &&
      //this.chosenExludedLabels.length &&
      this.chosenMetrics.length
    ) {
      this.selectedItemsEvent.emit(false);
    } else {
      this.selectedItemsEvent.emit(true);
    }
  }
}
