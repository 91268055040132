import { Component, OnInit } from '@angular/core';
import { AiDataService } from 'src/app/services/ai-data.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  aiVisited = false;

  constructor(private aiService: AiDataService) { }
  ngOnInit(): void {
    this.aiService.aiVisited$.subscribe(res => {
      this.aiVisited = res;
    })
  }
  newPredict(){
    this.aiService.updateAiVisited(false);
  }
}
