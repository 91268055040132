export const SuggestConstants = {

  SUGGESTION_TITLE: "Actionable Recommendations",
  SUGGESTION_BODY: "Applying one or more of the suggestions will improve the score of this creative",
  // SUGGESTION_BODY: "Applying one or more of the suggestions will improve the score of this creative to <value %>",

  /*SUGGEST_START_TEXT:"Consider including",
  SUGGEST_AN_OR_TEXT:"and/or",*/

}
