import { Component, Input } from '@angular/core';

@Component({
  selector: 'fm-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent {
  @Input() loaded = false;
  @Input() iconed = true;
  @Input() size = 137;
  @Input() loaderProgressColor = '#fda91e';
  @Input() loaderProgressBackground = '#ebe3e3';

  constructor() { }
}
