import { Component, HostListener, OnInit, ViewChild,  ElementRef, } from '@angular/core';
import { IDropdownWorker } from 'src/app/interfaces/dropdown-closer.interface';
import { DropdownsManagerService } from 'src/app/services/dropdowns-manager.service';

@Component({
  selector: 'fm-min-max',
  templateUrl: './min-max.component.html',
  styleUrls: ['./min-max.component.scss']
})
export class MinMaxComponent implements OnInit, IDropdownWorker {
  showTray = false;
  @ViewChild('minmax') minmax: any;
  filtered = false;
  constructor(private dropdownsManager: DropdownsManagerService,private eRef: ElementRef) {

  }
  registerToDropdownManager(dc: IDropdownWorker) {
    this.dropdownsManager.register(dc);
  }
  open(): void {
    this.showTray = true;
  }
  isOpen(): boolean {
    return this.showTray;
  }
  close(): void {
    this.showTray = false;
  }

  ngOnInit(): void {
    // this.registerToDropdownManager(this);
  }
  onClick() {

    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }

  }

  @HostListener('document:click', ['$event.target'])
  public onClickOutside(event): void {
    if (this.eRef.nativeElement.contains(event.target)) return;
    if (this.showTray && !this.minmax.nativeElement.contains(event)) {
      this.close();
    }
  }
  // public onClickOutside(targetElement: any): void {

  //   if (!this.showTray) return;
  //   if (this.showTray && !this.minmax.nativeElement.contains(targetElement)) {
  //     this.showTray = false;
  //   }
  // }

}
