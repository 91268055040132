import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { EAdDataType } from 'src/app/enums/ad-data-type.enum';
import { ControlValueAccessor,NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'fm-min-max-input',
  templateUrl: './min-max-input.component.html',
  styleUrls: ['./min-max-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MinMaxInputComponent),
      multi: true
    }]
})
export class MinMaxInputComponent implements OnInit {

  @Input() val: any;

  @Output() valChange = new EventEmitter();
  @Input() type: EAdDataType;
  typeCurrency: EAdDataType = EAdDataType.CURRENCY;
  typePercents: EAdDataType = EAdDataType.PERCENTS;
  typeSeconds: EAdDataType = EAdDataType.SECONDS;
  types = {
    [EAdDataType.NUMBER]: { placeholder: "0", decimal: false },
    [EAdDataType.CURRENCY]: { icon: "/assets/icons/dollar-sign-input.svg", placeholder: "0", decimal: false, class: "input-icon" },
    [EAdDataType.PERCENTS]: { icon: "/assets/icons/percents-sign-input.svg", placeholder: "0.00", decimal: true, class: "input-icon" },
    [EAdDataType.SECONDS]: { icon: "/assets/icons/sec-sign-input.svg", placeholder: "0", decimal: false, class: "input-icon seconds-icon" }
  }

  constructor() { }

  ngOnInit(): void {
  }


  onChange(event) {

    let { value } = event.target;
    if (value === '') {
      value = undefined;
    }
    this.valChange.emit(value);
  }

}
