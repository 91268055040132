
interface Error {
    message: string
}

export class UnkonwnError implements Error {
    message: string
    constructor(_message: string) {
        this.message = _message;
    }
}

export class AuthError implements Error {
    message: string
    constructor(_message: string) {
        this.message = _message;
    }
}

export class GeneralError implements Error {
    message: string
    constructor(_message: string) {
        this.message = _message;
    }
}

export class PollingEndedError implements Error {
    message: string
    constructor(_message: string) {
        this.message = _message;
    }
}

export class NoVideoIdError implements Error {
    message: string
    constructor(_message: string) {
        this.message = _message;
    }
}

export class VideoScoreError implements Error {
    message: string
    constructor(_message: string) {
        this.message = _message;
    }
}



