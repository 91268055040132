import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'fm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  title!: string

  constructor(private dataService: DataService, private loginService: LoginService) { }

  ngOnInit(): void {
    this.dataService.pageTitle$.subscribe( title => this.title = title)
  }
  login() {

    this.dataService.getFiltersOptions();
    this.dataService.getBiResults();


  }

  logout() {
    alert(2)
  }
}
